import { AxiosError } from 'axios';

import {
  commonError,
  getCheckoutPagesEP,
  createCheckoutPagesEP,
  deleteCheckoutPagesEP,
  updateCheckoutPagesEP,
} from '../services/constants';
import {
  IAddCheckoutPage,
  IDeleteCheckoutPage,
  IGetCheckoutPages,
  IGetCheckoutPagesResponse,
  IUpdateCheckoutPage,
} from '../../page-builder/interface/pageInterface';
import { basePortalService } from '../../page-builder/api/portalService';
import { ISuccessResponse } from '../../page-builder/interface/apiInterface';

class StoresMockService {
  getPages = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createPages = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updatePages = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCheckoutPage = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createCheckoutPage = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateCheckoutPage = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  deleteCheckoutPage = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class StoresService {
  storesMockService = new StoresMockService();

  // getPages = async (body: IGetPages) => {
  //   try {
  //     const response = await basePortalService.post(getPagesEP, body);
  //     return response;
  //   } catch (error) {
  //     // return this.settingsMockService.updateStore();
  //     return commonError(error as AxiosError, true);
  //   }
  // };

  // createPages = async (
  //   pageDetails: IPages,
  // ): Promise<ISuccessResponse<IPages> | IErrorResponse<AxiosError>> => {
  //   try {
  //     return await basePortalService.post<IPages>(createPagesEP, pageDetails);
  //   } catch (error) {
  //     return commonError(error as AxiosError, true);
  //   }
  // };

  // updatePages = async (
  //   pageDetails: IPages,
  // ): Promise<ISuccessResponse<IPages> | IErrorResponse<AxiosError>> => {
  //   try {
  //     return await basePortalService.post<IPages>(updatePagesEP, pageDetails);
  //   } catch (error) {
  //     return commonError(error as AxiosError, true);
  //   }
  // };

  getCheckoutPage = async (body: IGetCheckoutPages) => {
    try {
      const response = await basePortalService.post<IGetCheckoutPagesResponse>(
        getCheckoutPagesEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  createCheckoutPage = async (pageDetails: IAddCheckoutPage) => {
    try {
      return await basePortalService.post<{
        ID: string;
      }>(createCheckoutPagesEP, pageDetails);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateCheckoutPage = async (pageDetails: IUpdateCheckoutPage) => {
    try {
      return await basePortalService.post(updateCheckoutPagesEP, pageDetails);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  deleteCheckoutPage = async (pageDetails: IDeleteCheckoutPage) => {
    try {
      return await basePortalService.post(deleteCheckoutPagesEP, pageDetails);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalStoresService: StoresService | StoresMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new StoresMockService()
    : new StoresService();

export default globalStoresService;
