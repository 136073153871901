import {
  MarginProps,
  marginFields,
} from '../../../../page-builder/puck/reusable-props/margin';
import './styles.scss';
import { ComponentConfig, DropZone, FieldLabel } from '@measured/puck';
import {
  GapProps,
  gapField,
} from '../../../../page-builder/puck/reusable-props/gap';
import { flexDirections } from '../../../../page-builder/puck/reusable-props/options';
import {
  paddingFields,
  PaddingProps,
} from '../../../../page-builder/puck/reusable-props/padding';

export interface ContainerProps extends MarginProps, GapProps, PaddingProps {
  border: boolean;
  borderRadius: string;
  borderWidth: string;
  borderColor: string;
  backgroundColor: string;
  flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  position: string;
}

export const Container: ComponentConfig<ContainerProps> = {
  fields: {
    backgroundColor: {
      type: 'custom',
      render: ({ value, name, onChange }) => {
        const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        };
        return (
          <FieldLabel label="Background Color">
            <input
              type="color"
              id={name}
              name={name}
              value={value}
              onChange={handleChange}
            />
          </FieldLabel>
        );
      },
    },
    border: {
      type: 'radio',
      label: 'Border',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
    },
    borderRadius: {
      type: 'select',
      label: 'Border Radius',
      options: [
        { label: 'None', value: 'rounded-none' },
        { label: 'Small', value: 'rounded-sm' },
        { label: 'Medium', value: 'rounded-md' },
        { label: 'Large', value: 'rounded-lg' },
        { label: 'Full', value: 'rounded-full' },
      ],
    },
    borderWidth: {
      type: 'select',
      label: 'Border Width',
      options: [
        { label: 'None', value: 'border-0' },
        { label: '1', value: 'border-1' },
        { label: '2', value: 'border-2' },
        { label: '3', value: 'border-3' },
        { label: '4', value: 'border-4' },
        { label: '5', value: 'border-5' },
      ],
    },
    borderColor: {
      type: 'text',
      label: 'Border Color',
    },
    position: {
      type: 'radio',
      options: [
        { label: 'Sticky', value: 'sticky-container' },
        { label: 'None', value: '' },
      ],
    },
    ...marginFields,
    ...paddingFields,
    flexDirection: {
      label: 'Direction',
      type: 'select',
      options: flexDirections,
    },
    ...gapField,
  },
  defaultProps: {
    position: '',
    flexDirection: 'row',
    backgroundColor: 'none',
    border: false,
    borderRadius: 'rounded-none',
    borderWidth: 'border-0',
    borderColor: '#000000',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    gap: 'gap-2',
  },
  render: ({
    position,
    backgroundColor,
    border,
    borderRadius,
    borderWidth,
    borderColor,
    margin,
    padding,
    gap,
    flexDirection,
  }) => {
    return (
      <div
        className={`Container ${gap}
            ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right} 
            ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}
          ${border ? 'border' : ''} ${borderRadius} ${borderWidth} ${position}
          `}
        style={{ borderColor, backgroundColor, flexDirection }}>
        <DropZone zone={`Container`} />
      </div>
    );
  },
};
