import { ComponentConfig } from '@measured/puck';
import {
  CommonProps,
  commonProps,
} from '../../../page-builder/puck/reusable-props';
import { MarginProps } from '../../../page-builder/puck/reusable-props/margin';

export type TextFieldProps = {
  header: string;
} & MarginProps &
  CommonProps;

export const TextField: ComponentConfig<TextFieldProps> = {
  label: 'Text Field',
  fields: {
    header: { type: 'text', label: 'Header Text' },
    ...commonProps,
  },
  defaultProps: {
    header: 'Header',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    font: {
      size: 'text-2xl',
      weight: 'font-bold',
    },
    alignItems: 'items-center',
    borderRadius: 'rounded-md',
    height: 'full',
    justifyContent: 'justify-center',
    color: {
      backgroundColor: 'bg-white',
      textColor: 'text-gray-900',
      hoverColor: 'hover:bg-gray-100',
    },
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    width: 'w-full',
  },
  render: ({
    margin,
    header,
    font,
    alignItems,
    borderRadius,
    height,
    justifyContent,
    padding,
    color,
    width,
  }) => {
    return (
      <div
        className={`flex  ${font?.size} ${font?.weight} ${alignItems} ${borderRadius}  ${height} ${justifyContent} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}  ${width}
       ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}
        style={{
          backgroundColor: color?.backgroundColor,
          color: color?.textColor,
          transition: 'background-color 0.3s',
        }}>
        {header}
      </div>
    );
  },
};
