import { createSlice } from '@reduxjs/toolkit';
import { IStoreFront } from '../../page-builder/interface/settingsInterface';
import { IShop } from '../../page-builder/interface/storeFrontCommonInterface';
export interface shopifySliceProp {
  token: string;
  shop: IShop | null;
  storeFront: IStoreFront | null;
  isRegistered: boolean;
}
const initialState: shopifySliceProp = {
  token: '',
  shop: null,
  storeFront: null,
  isRegistered: false,
};
export const shopifySlice = createSlice({
  name: 'shopify',
  initialState,
  reducers: {
    setShopifyToken: (state, action) => {
      return { ...state, token: action.payload };
    },
    setShopifyShopDetails: (state, action) => {
      return { ...state, shop: action.payload };
    },
    setStoreFrontDetails: (state, action) => {
      return { ...state, storeFront: action.payload };
    },
    setRegistered: (state, action) => {
      return { ...state, isRegistered: action.payload };
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  setShopifyToken,
  setShopifyShopDetails,
  setStoreFrontDetails,
  setRegistered,
} = shopifySlice.actions;

export default shopifySlice.reducer;
