import { Home, PageNotFound } from 'shared-components';
import { useLocation } from 'react-router-dom';

export const Checkout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEditModeOn =
    queryParams.get('editMode') && queryParams.get('editMode') === 'false';
  if (location.pathname.includes('/page-builder') && isEditModeOn) {
    return <Home />;
  }
  return <>{!isEditModeOn && <PageNotFound />}</>;
};
