import { useFormContext } from 'react-hook-form';
import {
  RadioControl,
  RadioOptions,
} from '../../../page-builder/components/form-fields/Radio';
import { AddressForm } from './AddressForm';
import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';

export type BillingFormProps = MarginProps;

export const BillingForm: ComponentConfig<BillingFormProps> = {
  label: 'Billing Form',
  fields: {
    ...marginFields,
  },
  defaultProps: {
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({ margin }) => {
    const addressOptions: RadioOptions[] = [
      { id: '1', name: 'Same as shipping address', value: true },
      { id: '2', name: 'Use a different billing address', value: false },
    ];

    const { watch } = useFormContext();
    return (
      <>
        <div
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
          ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
          ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <RadioControl
            name="billing_same_as_shipping"
            options={addressOptions}
          />

          {!watch('billing_same_as_shipping') && (
            <div className="mt-10">
              <AddressForm fieldPrefix="BillingAddress" />
            </div>
          )}
        </div>
      </>
    );
  },
};
