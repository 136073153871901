import React from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { formatPrice } from '../../../../page-builder/utils/formatPrice';

export interface CartTotalProps {}

export const CartTotal: React.FC<CartTotalProps> = () => {
  const cartData = useAppSelector(state => state.cart.cartData);
  return (
    <>
      <div className="flex items-center font-semibold text-lg">
        {cartData?.LineItems === null || cartData?.LineItems?.length === 0
          ? formatPrice('0.0')
          : formatPrice(cartData?.TotalAmount)}
      </div>
    </>
  );
};
