import { AxiosError } from 'axios';
import { baseService } from '.';
import {
  commonError,
  createAssetsEP,
  deleteAssetsEP,
  searchAssetsEP,
} from '../../page-builder/api/constant';
import {
  StartCheckoutPayload,
  CheckoutDataPayload,
  GetPreCheckoutUpSellPayload,
  GetPreCheckoutUpSellResponse,
  GetPostCheckoutUpSellPayload,
  GetPostCheckoutUpSellResponse,
  CheckoutUpdateItemsPayload,
  CheckoutCompletePayload,
  CheckoutCompleteResponse,
  AddItemPayload,
  SetOrderDetailsResponse,
  SetContactInfoPayload,
  SetShippingAddressPayload,
  IGetImages,
  IGetImagesResponse,
} from '../../page-builder/interface/apiServiceInterface';
import {
  ApplyDiscountCodePayload,
  IDeleteAsset,
} from '../../page-builder/interface/checkoutInterface';
import { postUpsellPurchasePayload } from '../../page-builder/interface/postUpsellInterface';
import { basePortalService } from './portalService';

class CheckoutService {
  setContactInfo = async (payload: SetContactInfoPayload) => {
    try {
      const response = await baseService.post<SetOrderDetailsResponse>(
        '/checkout/set_contact_info',
        payload,
      );
      return response;
    } catch (error) {
      // here we can do the common error handling for this API
      throw commonError(error as AxiosError, true);
    }
  };
  setShippingAddress = async (payload: SetShippingAddressPayload) => {
    try {
      const response = await baseService.post<SetOrderDetailsResponse>(
        '/checkout/set_shipping_address',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutStart = async (payload: StartCheckoutPayload) => {
    try {
      const response = await baseService.post<CheckoutDataPayload>(
        '/checkout/start',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutComplete = async (payload: CheckoutCompletePayload) => {
    try {
      const response = await baseService.post<CheckoutCompleteResponse>(
        '/checkout/complete',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  getPreCheckoutUpSells = async (payload: GetPreCheckoutUpSellPayload) => {
    try {
      const response = await baseService.post<GetPreCheckoutUpSellResponse>(
        '/checkout/upsells/pre_checkout',
        payload,
        { timeout: 3000 },
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  getPostCheckoutUpSells = async (payload: GetPostCheckoutUpSellPayload) => {
    try {
      const response = await baseService.post<GetPostCheckoutUpSellResponse>(
        '/checkout/upsells/post_checkout',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutUpdateItems = async (payload: CheckoutUpdateItemsPayload) => {
    try {
      const response = await baseService.post<CheckoutDataPayload>(
        '/checkout/update_items',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutAddItem = async (payload: AddItemPayload) => {
    try {
      const response = await baseService.post<CheckoutDataPayload>(
        '/checkout/add_items',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  postUpsellPurchase = async (payload: postUpsellPurchasePayload) => {
    try {
      const response = await baseService.post(
        '/checkout/upsell/complete',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
  checkoutApplyDiscountCode = async (payload: ApplyDiscountCodePayload) => {
    try {
      const response = await baseService.post<CheckoutDataPayload>(
        '/checkout/apply_discount_code',
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  getAssets = async (payload: IGetImages) => {
    try {
      const response = await basePortalService.post<IGetImagesResponse>(
        searchAssetsEP,
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  createAsset = async (payload: FormData[]) => {
    try {
      // Create an array of promises for the API calls
      const requests = payload.map(formData =>
        basePortalService.post<{ ID: string }>(createAssetsEP, formData),
      );

      // Wait for all API calls to complete
      return await Promise.all(requests).then(responses => {
        return { status: 200, ...responses };
      });
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  deleteAsset = async (payload: IDeleteAsset) => {
    try {
      const responses = basePortalService.post<{ ID: string }>(
        deleteAssetsEP,
        payload,
      );
      return responses;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
}

const checkoutService = new CheckoutService();
export default checkoutService;
