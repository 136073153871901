import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { PaymentController } from './PaymentController';

export type PaymentProps = {
  subText?: string;
  isShowBillingAddress?: boolean;
} & MarginProps;

export const Payment: ComponentConfig<PaymentProps> = {
  fields: {
    subText: { type: 'text', label: 'Sub Text' },
    isShowBillingAddress: {
      type: 'radio',
      label: 'Show Billing Address',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    ...marginFields,
  },
  defaultProps: {
    subText: 'All transactions are secured and encrypted',
    isShowBillingAddress: true,
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({ margin, isShowBillingAddress, subText }) => {
    return (
      <PaymentController fields={{ margin, isShowBillingAddress, subText }} />
    );
  },
};
