import { ComponentConfig } from '@measured/puck';

export type DividerProps = {
  size: string;
  direction: string;
};

export const sizeOptions = [
  { label: '1px', value: '1px' },
  { label: '2px', value: '2px' },
  { label: '3px', value: '3px' },
  { label: '4px', value: '4px' },
];

export const directionOptions = [
  { label: 'horizontal', value: 'height' },
  { label: 'vertical', value: 'width' },
];

export const Divider: ComponentConfig<DividerProps> = {
  label: 'Divider',
  fields: {
    size: {
      type: 'select',
      options: sizeOptions,
    },
    direction: {
      type: 'select',
      options: directionOptions,
    },
  },
  defaultProps: {
    size: '1px',
    direction: 'height',
  },
  render: ({ size, direction }) => {
    return (
      <div
        style={{
          [direction]: size,
          [direction === 'height' ? 'width' : 'height']: '100%',
          background: 'black',
        }}
      />
    );
  },
};
