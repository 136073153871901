import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { useAppSelector } from '../../hooks/reduxHooks';
import { imageField } from '../../../page-builder/puck/reusable-props/image';
import {
  ImageProps,
  imageProps,
} from '../../../page-builder/puck/reusable-props/image-props';
import { CartIcon } from '../../../assets/icons/cartIcon';
import {
  fontField,
  FontProps,
} from '../../../page-builder/puck/reusable-props/font';
import {
  colorField,
  ColorProps,
} from '../../../page-builder/puck/reusable-props/color';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import {
  paddingFields,
  PaddingProps,
} from '../../../page-builder/puck/reusable-props/padding';

export type CheckoutHeaderProps = {
  imageFile: IAsset;
  backgroundImageFile: IAsset;
  headerText: string;
} & MarginProps &
  ImageProps &
  FontProps &
  ColorProps &
  PaddingProps;

export const CheckoutHeader: ComponentConfig<CheckoutHeaderProps> = {
  label: 'Checkout Header',
  fields: {
    imageFile: { ...imageField(true).file },
    backgroundImageFile: { ...imageField(false).file },
    headerText: { type: 'text', label: 'Header Text' },
    ...marginFields,
    ...paddingFields,
    ...imageProps(['imageType']),
    ...fontField,
    ...colorField,
  },
  defaultProps: {
    backgroundImageFile: {
      Version: 0,
      StoreID: '',
      Filename: 'background',
      BlobURL: 'https://picsum.photos/id/0/5000/3333',
    },
    imageFile: {
      Version: 0,
      StoreID: '',
      Filename: 'logo',
      BlobURL: 'https://picsum.photos/id/10/2500/1667',
    },
    headerText: 'My Store',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    image: {
      imageType: 'inline',
      backgroundPosition: 'bg-center',
      backgroundRepeat: 'bg-no-repeat',
      backgroundSize: 'bg-cover',
      borderRadius: 'rounded-md',
      border: 'border-none',
      maxWidth: 'w-auto',
      height: 'h-[100px]',
    },

    font: {
      size: 'text-2xl',
      weight: 'font-bold',
    },
    color: {
      backgroundColor: 'bg-white',
      textColor: 'text-gray-900',
      hoverColor: 'hover:bg-gray-100',
    },
  },
  render: ({
    imageFile,
    backgroundImageFile,
    headerText,
    margin,
    image,
    font,
    color,
    padding,
  }) => {
    const storeData = useAppSelector(state => state.store.storeData);
    const handleClick = () => {
      if (storeData?.ShopURL) {
        window.location.href = storeData?.ShopURL;
      }
    };
    return (
      <>
        <div
          className={`grid w-full grid-cols-[max-content_1fr_max-content] items-center
            max-[760px]:!bg-none  md:relative top-0 left-0 right-0 z-10 
            ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}
            ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}
            ${color?.backgroundColor}
            ${image?.backgroundPosition}
            ${image?.backgroundRepeat}
            ${image?.backgroundSize}
            ${image?.border}
            ${image?.maxWidth}
            ${image?.borderRadius}
            ${image?.height}
          `}
          style={{
            backgroundImage: `url(${backgroundImageFile?.BlobURL})`,
          }}>
          <div
            className="flex cursor-pointer w-[100px] h-[100px] p-3"
            onClick={handleClick}>
            {imageFile?.BlobURL ? (
              <img src={imageFile?.BlobURL} alt={imageFile?.Filename} />
            ) : (
              <p className="flex items-center bg-slate-500  text-wrap font-12 font-semibold">
                {' '}
                Upload logo
              </p>
            )}
          </div>
          {headerText && (
            <div className="flex items-center justify-center h-full overflow-hidden">
              <p
                className={` ${font?.size} ${font?.weight} ${color?.hoverColor}`}
                style={{
                  color: `${color?.textColor}`,
                }}>
                {headerText}
              </p>
            </div>
          )}
          <div
            className="block cursor-pointer p-2 mr-2  w-[40px] h-[40px]"
            onClick={handleClick}>
            <CartIcon />
          </div>
        </div>
      </>
    );
  },
};
