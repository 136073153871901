import { Checkout } from 'components/checkout/checkout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PageNotFound } from 'shared-components';

export const CheckoutRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/page-builder/*" element={<Checkout />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
