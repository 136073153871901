import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useSearchParams } from 'react-router-dom';
import { setCartData } from '../../../page-builder/redux/slices/cartSlice';
import { ApplyDiscountCodePayload } from '../../../page-builder/interface/checkoutInterface';
import checkoutService from '../../../page-builder/api/checkoutService';
import {
  marginFields,
  MarginProps,
} from '../../../page-builder/puck/reusable-props/margin';
import { ComponentConfig } from '@measured/puck';

const ERROR_EMPTY_PROMO = 'Please enter your promo code.';

const ERROR_INVALID_PROMO = 'Invalid promo code.';

export type PromoCodeProps = {
  placeholder: string;
} & MarginProps;

export const PromoCode: ComponentConfig<PromoCodeProps> = {
  label: 'Promo Code',
  fields: {
    placeholder: { type: 'text', label: 'Header Text' },
    ...marginFields,
  },
  defaultProps: {
    placeholder: 'Promo Code (Case Sensitive)',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({ placeholder, margin }) => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const shopId = searchParams.get('store')?.split('.')?.[0];
    const cartData = useAppSelector(state => state.cart?.cartData);

    const [promoCode, setPromoCode] = useState('');
    const [promoMessage, setPromoMessage] = useState({ text: '', status: '' });

    // Update only text in promoMessage keeping the status intact
    const updatePromoMessageText = useCallback((text: string) => {
      setPromoMessage(prevState => ({ ...prevState, text }));
    }, []);

    const handleApplyPromoCode = async () => {
      if (!promoCode.trim()) {
        setPromoMessage({ text: ERROR_EMPTY_PROMO, status: 'error' });
        return;
      }

      const payload = {
        DiscountCodes: [promoCode],
        ID: cartData?.ID,
        ShopID: shopId,
        StoreID: cartData?.StoreID,
      };

      try {
        const res = await checkoutService.checkoutApplyDiscountCode(
          payload as ApplyDiscountCodePayload,
        );
        dispatch(setCartData(res?.data?.Cart));
      } catch (error) {
        setPromoMessage({ text: ERROR_INVALID_PROMO, status: 'error' });
      }
    };

    const messageClass =
      promoMessage.status === 'success' ? 'success-message' : 'error-message';

    return (
      <>
        <div
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <div className="mt-1 flex space-x-4">
            <input
              type="text"
              id="discount-code-mobile"
              name="discount-code-mobile"
              placeholder={placeholder}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder:text-sm"
              value={promoCode}
              onChange={e => setPromoCode(e.target.value)}
              onBlur={() =>
                updatePromoMessageText(
                  promoCode.trim() ? '' : ERROR_EMPTY_PROMO,
                )
              }
            />
            <button
              id="promo-button"
              type="button"
              className="rounded-md px-4 text-sm text-white font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 bg-page_builder_theme"
              onClick={handleApplyPromoCode}>
              Apply
            </button>
          </div>
          <div className={messageClass} style={{ color: 'var(--error-color)' }}>
            {promoMessage.text}
          </div>
        </div>
      </>
    );
  },
};

export default PromoCode;
