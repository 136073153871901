import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../../page-builder/puck/reusable-props/margin';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux-setup/store';
import {
  JustifyContentField,
  JustifyContentProps,
} from '../../../../page-builder/puck/reusable-props/justify-content';
import {
  fontField,
  FontProps,
} from '../../../../page-builder/puck/reusable-props/font';
import {
  AlignItemsProps,
  alignItemsField,
} from '../../../../page-builder/puck/reusable-props/align-items';
import { useCallback } from 'react';
import { ErrorResponse, useNavigate } from 'react-router-dom';
import {
  SelectedItem,
  postUpsellPurchasePayload,
} from '../../../../page-builder/interface/postUpsellInterface';
import checkoutService from '../../../../page-builder/api/checkoutService';
import {
  setCurrentIndex,
  setErrorStatus,
  setIsLoading,
  setPopupMessage,
  setShowPopup,
} from '../../../../page-builder/redux/slices/postUpsellStateSlice';
import {
  IDropdownOptions,
  navigationPathField,
} from '../../../../page-builder/puck/reusable-props/navigation-path-field';
import {
  paddingFields,
  PaddingProps,
} from '../../../../page-builder/puck/reusable-props/padding';

export type NextButtonProps = {
  NextButton: string;
  nextPagePath: IDropdownOptions;
} & MarginProps &
  PaddingProps &
  JustifyContentProps &
  FontProps &
  AlignItemsProps;

export const NextButton: ComponentConfig<NextButtonProps> = {
  fields: {
    NextButton: { type: 'text', label: 'NextButton' },
    ...navigationPathField,
    ...marginFields,
    ...paddingFields,
    ...JustifyContentField,
    ...fontField,
    ...alignItemsField,
  },
  defaultProps: {
    NextButton: 'NextButton',
    nextPagePath: { label: '', value: '' },
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    justifyContent: 'justify-center',
    font: {
      size: 'text-2xl',
      weight: 'font-bold',
    },
    alignItems: 'items-center',
  },
  render: ({
    NextButton,
    nextPagePath,
    margin,
    padding,
    justifyContent,
    alignItems,
  }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const checkoutState = useSelector((state: RootState) => state.checkout);
    const postUpsell = useSelector((state: RootState) => state.postUpSell);
    const postUpsellState = useSelector(
      (state: RootState) => state.postUpSellState,
    );
    const storeState = useSelector((state: RootState) => state.store);
    const orderID = checkoutState.checkoutOrderID;
    const purchaseToken = checkoutState.purchaseToken;
    const postUpSellRecommendation = postUpsell.postUpsellData;
    const storeData = storeState.storeData;

    // const newShopUrl = storeData?.ShopURL?.replace('https://', '');
    // const url = `/confirmation-page?store=${newShopUrl}`;
    const handleCompletePurchase = useCallback(
      async (selectedItems: SelectedItem[]) => {
        if (selectedItems.length === 0) {
          navigate(nextPagePath?.value);
          return;
        }

        const payload: postUpsellPurchasePayload = {
          LineItems: selectedItems
            .filter(item => item.variant.ID)
            .map(item => ({
              Quantity: 1,
              VariantID: item.variant.ID,
            })),
          OrderID: orderID,
          PurchaseToken: purchaseToken,
          ShopID: storeData?.ShopID,
          StoreID: storeData?.StoreID || '',
        };

        try {
          const res = await checkoutService.postUpsellPurchase(payload);

          if (res.status === 200) {
            dispatch(setIsLoading(false));
            navigate(nextPagePath?.value);
          }
        } catch (error: unknown) {
          const err = error as ErrorResponse;
          if (err.status === 400) {
            dispatch(setIsLoading(false));
            dispatch(
              setPopupMessage(
                'No worries, we are still processing your original order, please dismiss to continue.',
              ),
            );
            dispatch(setErrorStatus(err.status));
            dispatch(setShowPopup(true));
            throw error;
          } else {
            dispatch(setIsLoading(false));
            dispatch(
              setPopupMessage(
                'Your previous purchase is secured. There was an error processing your current request, please dismiss and try again.',
              ),
            );
            dispatch(setShowPopup(true));
            throw error;
          }
        }
      },
      [navigate, nextPagePath, orderID, purchaseToken, storeData],
    );

    const handleNextRecommendation = useCallback(() => {
      dispatch(setIsLoading(true));
      setTimeout(() => {
        if (postUpsellState.selectedItems.length > 0) {
          handleCompletePurchase(postUpsellState.selectedItems);
          dispatch(setIsLoading(false));
        } else if (
          postUpsellState.currentIndex + 1 >=
          postUpSellRecommendation.length
        ) {
          navigate(nextPagePath?.value);
          dispatch(setIsLoading(false));
        } else {
          dispatch(setCurrentIndex(postUpsellState.currentIndex + 1));
          dispatch(setIsLoading(false));
        }
      }, 1000);
    }, [
      setCurrentIndex,
      postUpsellState.currentIndex,
      postUpSellRecommendation,
      navigate,
      nextPagePath,
      postUpsellState.selectedItems,
      handleCompletePurchase,
    ]);
    return (
      <div
        className={`flex  w-full h-full ${justifyContent} ${alignItems} ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`}>
        <button
          onClick={() => handleNextRecommendation()}
          className="mt-4 text-blue-500 text-sm underline mx-auto">
          {NextButton}
        </button>
      </div>
    );
  },
};
