import { useEffect, useCallback } from 'react';
import { ComponentConfig } from '@measured/puck';
import { setCheckoutErrorMessage } from '../../redux/slices/commonSlice';
import { useFormContext } from 'react-hook-form';
import { setApiLoading } from '../../redux/slices/checkoutSlice';
import { setAvailableShippingMethods } from '../../redux/slices/checkoutSlice';
import useDebounce from '../../hooks/useDebounce';
import checkoutService from '../../api/checkoutService';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { ContactInformationFormType } from '../../../page-builder/interface/formInterface';
import { V2ShippingMethod } from '../../../page-builder/interface/checkoutInterface';
import { emailRegExp } from '../../../page-builder/utils/constants';
import { TextFieldControl } from '../form-fields/TextField';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';

export type ContactInfoProps = MarginProps;

export const ContactInfo: ComponentConfig<ContactInfoProps> = {
  label: 'Contact Info',
  fields: {
    ...marginFields,
  },
  defaultProps: {
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({ margin }) => {
    const dispatch = useAppDispatch();
    const {
      watch,
      formState: { errors },
    } = useFormContext<ContactInformationFormType>();

    const storeData = useAppSelector(state => state.store.storeData);
    const cartData = useAppSelector(state => state.cart.cartData);
    const { Email, PhoneNumber } = watch('CustomerData');
    const PhoneCode = watch('phone_code');

    const isCustomerDetailsValid = !!Email && !errors?.CustomerData?.Email;

    const updateCustomerDetails = useCallback(async () => {
      if (
        isCustomerDetailsValid &&
        cartData?.ID &&
        storeData?.ShopID &&
        storeData?.StoreID
      ) {
        const payload = {
          Email,
          PhoneNumber: PhoneNumber ? `${PhoneCode}${PhoneNumber}` : null,
          ID: cartData.ID,
          ShopID: storeData.ShopID,
          StoreID: storeData.StoreID,
        };

        dispatch(setApiLoading(true));

        try {
          const res = await checkoutService.setContactInfo(payload);
          dispatch(
            setAvailableShippingMethods(
              res.data.AvailableShippingMethods as [V2ShippingMethod],
            ),
          );
        } catch (error: unknown) {
          if (error instanceof Error) {
            dispatch(setCheckoutErrorMessage(error.message));
            return;
          }

          dispatch(setCheckoutErrorMessage('An unknown error occurred'));
        }
      }
      dispatch(setApiLoading(false));
    }, [
      dispatch,
      isCustomerDetailsValid,
      Email,
      PhoneNumber,
      PhoneCode,
      cartData?.ID,
      storeData?.ShopID,
      storeData?.StoreID,
    ]);

    const updateCustomerDetailsDebounce = useDebounce(
      updateCustomerDetails,
      500,
    );

    useEffect(() => {
      updateCustomerDetailsDebounce();
    }, [
      isCustomerDetailsValid,
      Email,
      errors.CustomerData?.Email,
      updateCustomerDetailsDebounce,
    ]);

    return (
      <>
        <section
          aria-labelledby="contact-info-heading"
          className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
          <TextFieldControl
            name="CustomerData.Email"
            placeholder="Email"
            rules={{
              required: 'Please enter your email address',
              pattern: {
                value: emailRegExp,
                message: 'Please enter a valid email address',
              },
            }}
          />
        </section>
      </>
    );
  },
};
