import { useState, useEffect } from 'react';
import { ComponentConfig } from '@measured/puck';
import { DropZone } from '@measured/puck';
import { CartTotal } from '../../../../page-builder/components/cart/cart-total/CartTotal';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import './styles.scss';

export interface SplitLayoutProps {
  border: boolean;
  bgColor: string;
  borderColor: string;
  orderSummaryText: string;
  orderSummaryTextColor: string;
  orderSummaryCartTotal: boolean;
}

export const SplitLayout: ComponentConfig<SplitLayoutProps> = {
  label: 'Simple Split Layout',
  fields: {
    border: {
      type: 'radio',
      label: 'Border',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
    },
    borderColor: {
      type: 'text',
      label: 'Border Color',
    },
    bgColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel label="Background Color">
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    orderSummaryText: {
      type: 'text',
      label: 'Order Summary Text',
    },
    orderSummaryTextColor: {
      type: 'custom',
      label: 'Summary Text Color',
      render: ({ value, onChange }) => (
        <FieldLabel label="Text Color">
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    orderSummaryCartTotal: {
      type: 'radio',
      label: 'Mobile Summary Cart Total',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
    },
  },
  defaultProps: {
    border: true,
    borderColor: '#E5E5E5',
    bgColor: '#F9F9F9',
    orderSummaryCartTotal: true,
    orderSummaryTextColor: '#000',
    orderSummaryText: 'Show order summary',
  },
  render: ({
    border,
    bgColor,
    borderColor,
    orderSummaryCartTotal,
    orderSummaryText,
    orderSummaryTextColor,
  }) => {
    const [dropDown, setDropDown] = useState(false);
    const [total, setTotal] = useState(true);
    const toggleDropZone = () => setDropDown(!dropDown);
    useEffect(() => {
      const handleResize = () => {
        setDropDown(window.innerWidth >= 1024);
        setTotal(window.innerWidth <= 1024);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <>
        <div
          className={`split-layout-grid ${border ? 'lg:border-t-2' : ''}`}
          style={{
            borderColor: borderColor,
          }}>
          <div
            className={`left-split-layout mx-auto lg:max-w-none split-layout-child pl-8 pr-8 pb-20 pt-8 lg:pl-24 xl:pl-40 2xl:pl-80`}
            style={{ width: '50%' }}>
            <DropZone zone="left" />
          </div>
          <div
            className={`right-split-layout split-layout-child ${border ? 'lg-max:border-l-0 border-l-2' : ''} pt-8 pl-8 pr-8 pb-8 lg:pb-20 lg:pr-24 xl:pr-40 2xl:pr-80`}
            style={{
              backgroundColor: bgColor,
              width: '50%',
            }}>
            <div className="flex justify-between">
              <button
                type="button"
                className="flex items-center display-on-mobile-only"
                style={{ color: orderSummaryTextColor }}
                onClick={toggleDropZone}>
                {orderSummaryText}
                <ChevronDownIcon
                  className={`h-5 w-5 ml-2 transition-transform duration-200 ${
                    dropDown ? 'transform rotate-180' : ''
                  }`}
                  aria-hidden="true"
                />
              </button>
              {total && orderSummaryCartTotal && <CartTotal />}
            </div>
            {dropDown && <DropZone zone="right" />}
          </div>
        </div>
      </>
    );
  },
};
