import React, {
  useEffect,
  useCallback,
  ChangeEvent,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  usaStates,
  australiaStates,
  canadaStates,
  chinaStates,
  newZealandStates,
  mexicoStates,
  brazilStates,
  malaysiaStates,
  southKoreaStates,
} from '../../../page-builder/data/state';
import { Controller, useFormContext } from 'react-hook-form';
import { countries } from '../../../page-builder/data/country';
import { TextFieldControl } from '../../../page-builder/components/form-fields/TextField';
import {
  Select,
  SelectControl,
} from '../../../page-builder/components/form-fields/Select';
import { AddressFormType } from '../../../page-builder/interface/formInterface';
import { loadGoogleAutoComplete } from '../../../page-builder/utils/loadGoogleAutoComplete';

interface AddressFormProps {
  fieldPrefix: string;
}

export const checkStateRequirement = (
  country: string,
  countryCodes: string[],
) => {
  if (countryCodes.includes(country)) {
    return true;
  }
  return false;
};

export const AddressForm: React.FC<AddressFormProps> = ({ fieldPrefix }) => {
  const address1FieldRef = useRef<HTMLInputElement>(null);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();

  const {
    setValue,
    watch,
    control,
    formState: { errors },
  } = useFormContext<AddressFormType>();

  const isValid =
    watch(`${fieldPrefix}.FirstName`) &&
    watch(`${fieldPrefix}.LastName`) &&
    watch(`${fieldPrefix}.Address1`) &&
    watch(`${fieldPrefix}.City`) &&
    watch(`${fieldPrefix}.CountryCode`) &&
    watch(`${fieldPrefix}.ProvinceCode`) &&
    watch(`${fieldPrefix}.Zip`) &&
    !errors.fieldPrefix?.FirstName &&
    !errors.fieldPrefix?.LastName &&
    !errors.fieldPrefix?.Address1 &&
    !errors.fieldPrefix?.City &&
    !errors.fieldPrefix?.CountryCode &&
    !errors.fieldPrefix?.ProvinceCode &&
    !errors.fieldPrefix?.Zip;

  useEffect(() => {
    if (isValid) {
      // metaPixelTracker({
      //   eventName:
      //     fieldPrefix === 'billing_address' ? 'BillingAdded' : 'ShippingAdded',
      // });
    }
  }, [isValid, fieldPrefix]);

  const [stateVisible, setStateVisible] = useState<boolean>(false);

  const countryStatesMapping = {
    US: usaStates,
    AU: australiaStates,
    BR: brazilStates,
    MX: mexicoStates,
    MY: malaysiaStates,
    NZ: newZealandStates,
    KR: southKoreaStates,
    CN: chinaStates,
    CA: canadaStates,
  };

  const selectedCountryStates =
    countryStatesMapping[
      watch(`${fieldPrefix}.CountryCode`) as keyof typeof countryStatesMapping
    ];

  const countryCodes = useMemo(
    () => countries.map(country => country.code),
    [],
  );

  const changeStateVisibility = useCallback(
    (country: string) => {
      const isStateRequired = checkStateRequirement(country, countryCodes);
      if (isStateRequired) {
        setStateVisible(true);
      } else {
        setValue(`${fieldPrefix}.ProvinceCode`, '');
        setStateVisible(false);
      }
    },
    [setValue, setStateVisible, fieldPrefix, countryCodes],
  );

  const handleCountryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    changeStateVisibility(event.target.value);
    setValue(`${fieldPrefix}.CountryCode`, event.target.value);
  };

  const fillInAddress = useCallback(() => {
    const autoComplete = autoCompleteRef.current;
    if (autoComplete) {
      const place = autoComplete.getPlace();
      let address = '';
      let city = '';
      let country = '';
      let state = '';
      let zip = '';

      for (const component of place.address_components ?? []) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number':
            address = `${component.long_name} ${address}`;
            break;

          case 'route':
            address += component.short_name;
            break;

          case 'locality':
            city = component.long_name;
            break;

          case 'country' || 'political':
            country = component.short_name;
            break;

          case 'administrative_area_level_1':
            state = component.short_name;
            break;

          case 'postal_code':
            zip = component.long_name;
            break;
        }
      }

      setValue(`${fieldPrefix}.Address1`, address);
      setValue(`${fieldPrefix}.City`, city);
      setValue(`${fieldPrefix}.CountryCode`, country);
      setValue(`${fieldPrefix}.ProvinceCode`, state);
      setValue(`${fieldPrefix}.Zip`, zip);
      changeStateVisibility(country);
    }
  }, [setValue, changeStateVisibility, autoCompleteRef, fieldPrefix]);

  useEffect(() => {
    const loadScript = async () => {
      try {
        await loadGoogleAutoComplete();
        waitForGoogleMapsPlaces(() => {
          if (
            address1FieldRef.current &&
            address1FieldRef.current.id === fieldPrefix
          ) {
            autoCompleteRef.current = new google.maps.places.Autocomplete(
              address1FieldRef.current,
              {
                fields: ['address_components', 'geometry'],
                types: ['address'],
              },
            );
            autoCompleteRef.current.addListener('place_changed', fillInAddress);
          }
        });
      } catch (error) {
        console.error(
          'Failed to load the Google Maps JavaScript API script. Error: ',
          error,
        );
      }
    };

    loadScript();
    changeStateVisibility('US');
  }, [fieldPrefix, fillInAddress]);

  function waitForGoogleMapsPlaces(
    callback: () => void,
    attemptsLeft = 10,
    interval = 300,
  ) {
    if (typeof google !== 'undefined' && google.maps && google.maps.places) {
      callback();
    } else if (attemptsLeft === 0) {
      console.error(
        'Google Maps Places API failed to load within the expected time.',
      );
    } else {
      setTimeout(() => {
        waitForGoogleMapsPlaces(callback, attemptsLeft - 1, interval);
      }, interval);
    }
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-y-[10px] sm:grid-cols-2 sm:gap-x-4 font-light">
        <TextFieldControl
          id={`${fieldPrefix}.FirstName`}
          name={`${fieldPrefix}.FirstName`}
          placeholder="First Name"
          autoComplete="given-name"
          rules={{
            required: 'First name is a required field',
          }}
        />
        <TextFieldControl
          id={`${fieldPrefix}.LastName`}
          name={`${fieldPrefix}.LastName`}
          placeholder="Last Name"
          autoComplete="family-name"
          rules={{
            required: 'Last name is a required field',
          }}
        />
        <div className="block w-full sm:col-span-2">
          <Controller
            name={`${fieldPrefix}.Address1`}
            defaultValue={''}
            control={control}
            rules={{ required: 'Address is a required field' }}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                id={`${fieldPrefix}`}
                className="block w-full h-[42px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                ref={address1FieldRef}
                placeholder="Address"
              />
            )}
          />
          <div
            className="error-message"
            style={{ color: 'var(--error-color)' }}>
            {errors?.[fieldPrefix]?.Address1?.message}
          </div>
        </div>
        <TextFieldControl
          className="sm:col-span-2"
          name={`${fieldPrefix}.Address2`}
          placeholder="Apartment, suite, etc. (optional)"
        />
        <TextFieldControl
          className="sm:col-span-2"
          id={`${fieldPrefix}.Organization`}
          name={`${fieldPrefix}.Organization`}
          placeholder="Company (optional)"
        />
        <TextFieldControl
          id={`${fieldPrefix}.City`}
          name={`${fieldPrefix}.City`}
          placeholder="City"
          rules={{
            required: 'City is a required field',
          }}
        />
        <Controller
          name={`${fieldPrefix}.CountryCode`}
          render={({
            field: { onChange, ...restField },
            fieldState: { error },
          }) => {
            return (
              <Select
                options={countries}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleCountryChange(e);
                  onChange(e.target.value);
                }}
                additionalStyles={'!text-gray-700'}
                valueKey="code"
                labelKey="name"
                {...restField}
                error={error?.message}
              />
            );
          }}
        />
        {stateVisible && (
          <div>
            <div className="relative">
              {watch(`${fieldPrefix}.CountryCode`) === 'US' ||
              watch(`${fieldPrefix}.CountryCode`) === 'AU' ||
              watch(`${fieldPrefix}.CountryCode`) === 'BR' ||
              watch(`${fieldPrefix}.CountryCode`) === 'MX' ||
              watch(`${fieldPrefix}.CountryCode`) === 'MY' ||
              watch(`${fieldPrefix}.CountryCode`) === 'NZ' ||
              watch(`${fieldPrefix}.CountryCode`) === 'KR' ||
              watch(`${fieldPrefix}.CountryCode`) === 'CN' ||
              watch(`${fieldPrefix}.CountryCode`) === 'CA' ? (
                <SelectControl
                  name={`${fieldPrefix}.ProvinceCode`}
                  options={selectedCountryStates}
                  defaultOption=" Select a state"
                  valueKey="code"
                  labelKey="name"
                />
              ) : (
                <TextFieldControl
                  name={`${fieldPrefix}.ProvinceCode`}
                  id={`${fieldPrefix}.ProvinceCode`}
                  placeholder="State"
                  rules={{
                    required: 'State is a required field',
                    validate: value =>
                      value.trim() !== '' || 'State cannot be just spaces',
                  }}
                />
              )}
              <div
                className="error-message"
                style={{ color: 'var(--error-color)' }}>
                {errors?.[fieldPrefix]?.ProvinceCode?.message}
              </div>
            </div>
          </div>
        )}

        <TextFieldControl
          id={`${fieldPrefix}.postcode`}
          autoComplete="postal-code"
          name={`${fieldPrefix}.Zip`}
          placeholder="Postal code"
          rules={{
            required: 'Postal code is a required field',
          }}
        />
      </div>
    </>
  );
};
