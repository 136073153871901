import { Fields } from '@measured/puck';
import { getPagePath } from '../../../page-builder/utils/constants';
import { MenuItem, Switch, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import { ICheckoutPage } from '../../../page-builder/interface/pageInterface';
import { useSearchParams } from 'react-router-dom';
import globalPageService from '../../services/pageService';
import { Loader } from '../../../components/common/loader';

export type navigationPathProps = {
  nextPagePath: IDropdownOptions;
};

export interface IDropdownOptions {
  label: string;
  value: string;
}

export const navigationPathField: Fields<navigationPathProps> = {
  nextPagePath: {
    type: 'custom',
    label: 'Next Page',
    render: ({ value, onChange }) => {
      value = value || {
        label: '',
        value: '',
      };
      const [pagesDetails, setPagesDetails] = useState<IDropdownOptions[]>([]);
      const [checked, setChecked] = useState<boolean>(true);
      const [searchParams] = useSearchParams();
      const [isRequired, setIsRequired] = useState<boolean>(false);
      const [loading, setLoading] = useState<boolean>(true);
      const [checkoutPage, setCheckoutPage] = useState<ICheckoutPage | null>(
        null,
      );
      const [checkoutPageResponse, setcheckoutPageResponse] = useState<
        ICheckoutPage[]
      >([]);
      const storeID = searchParams.get('storeID') || '';
      const pageID = searchParams.get('pageID') || '';

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          label: event.target.value,
          value: event.target.value,
        });
      };

      const getPages = async () => {
        if (!checkoutPage?.StoreID || !checkoutPage?.PageType) return;
        setLoading(true);
        const res = await globalPageService.searchPages({
          Descending: true,
          Limit: 100,
          OrderBy: 'Name',
          Page: 0,
          StoreID: checkoutPage?.StoreID,
        });
        setLoading(false);
        setcheckoutPageResponse(res?.data?.Result);
        if (res?.status === 200 && res?.data?.Result?.length) {
          const filtredData: IDropdownOptions[] = [];
          res.data.Result.forEach((item: ICheckoutPage) => {
            if (!item?.ID) {
              return;
            }
            if (
              checkoutPage?.PageType === 'checkout' &&
              item.PageType !== 'checkout'
            ) {
              filtredData.push({
                label: item?.Name,
                value: item.ID,
              });
            } else if (
              checkoutPage?.PageType === 'upsell' &&
              item.PageType !== 'upsell' &&
              item.PageType !== 'checkout'
            ) {
              filtredData.push({
                label: item?.Name,
                value: item.ID,
              });
            } else if (
              checkoutPage?.PageType === 'confirmation' &&
              item.PageType !== 'confirmation' &&
              item.PageType !== 'checkout'
            ) {
              filtredData.push({
                label: item?.Name,
                value: item.ID,
              });
            }
          });
          setChecked(
            filtredData.filter(item => item?.value === value?.label).length > 0
              ? true
              : value.value
                ? false
                : true,
          );
          setPagesDetails(filtredData);
        }
      };

      const appendURL = (value: string) => {
        //if user selects from dropdown
        const pageData = checkoutPageResponse.find(page => page.ID === value);
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.set('editMode', 'false');
        if (pageData) {
          updatedSearchParams.set('pageID', pageData?.ID || '');
          updatedSearchParams.set('template', pageData.PageType);
        }
        return getPagePath(updatedSearchParams.toString());
      };

      const getPageDeatils = async () => {
        const res = await globalPageService.getPage(storeID, pageID);
        if (res?.status === 200 && res?.data) {
          setCheckoutPage(res?.data);
        }
      };

      useEffect(() => {
        getPages();
      }, [checkoutPage]);

      useEffect(() => {
        getPageDeatils();
      }, []);

      useEffect(() => {
        value = {
          label: value.label,
          value: value.label,
        };
      }, [pagesDetails]);

      return (
        <div className="flex flex-col relative">
          <Loader loading={loading} />
          <div className="flex justify-between items-center">
            <p className="text-sm font-semibold">Next Page</p>
            <div className="flex gap-0.5 items-center">
              <Tooltip
                title={`Toggle for ${checked ? 'free text' : 'dropdown'} `}
                placement="top-start">
                <InfoIcon fontSize="small" className="h-1 w-1" />
              </Tooltip>
              <Switch
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                  setIsRequired(false);
                  onChange({ label: '', value: '' });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
          </div>
          <div className="flex w-full mt-2">
            {checked ? (
              <>
                <div className="w-full">
                  <Select
                    placeholder="Select Next Page"
                    className="min-w-[160px] !rounded-md px-2 py-1 font-12 h-10  !w-full"
                    value={value?.label}
                    onChange={event => {
                      setIsRequired(false);
                      onChange({
                        label: event.target.value,
                        value: appendURL(event.target.value),
                      });
                    }}
                    onBlur={() => {
                      setIsRequired(value.value?.trim() === '');
                    }}>
                    {pagesDetails?.map(option => (
                      <MenuItem key={option?.value} value={option?.value}>
                        {option?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {isRequired && (
                    <div className="text-red-500 font-10 mt-1 ml-1">
                      Please select an option.
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="!w-full">
                <input
                  type="text"
                  placeholder='Enter next page path like "/page-builder/page-1"'
                  value={value?.label}
                  onChange={handleChange}
                  onBlur={() => {
                    setIsRequired(value.value.trim() === '');
                  }}
                  className="!w-full border border-gray-300 rounded-md px-2 py-1 font-12 h-10"
                />
                {isRequired && (
                  <div className="text-red-500 font-10 mt-1 ml-1">
                    This field is required.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    },
  },
};
