import { ComponentConfig } from '@measured/puck';
import 'react-quill/dist/quill.snow.css';
import { QuillTextEditor } from '../../../page-builder/puck/reusable-props/quill-editor';

export type TextEditorProps = {
  content: string;
};

export const TextEditor: ComponentConfig<TextEditorProps> = {
  label: 'Text Editor',
  fields: {
    content: {
      ...QuillTextEditor('Next Page').content,
    },
  },
  defaultProps: {
    content: '',
  },
  render: ({ content }) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  },
};
