import React from 'react';
import { TermsAndSubscription } from '../terms-and-subscription/TermsAndSubscription';
import { useLocation } from 'react-router-dom';
import { ComponentConfig } from '@measured/puck';
import { useAppSelector } from '../../hooks/reduxHooks';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';

export type TermsAndSubProps = MarginProps;

export const TermsAndSub: ComponentConfig<TermsAndSubProps> = {
  label: 'Terms And Subs',
  fields: marginFields,
  render: ({ margin }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isEditModeOn = queryParams.get('editMode') === 'true';
    const hasSubscription = useAppSelector(
      state => state.checkout.hasSubscription,
    );
    const storeData = useAppSelector(state => state.store.storeData);
    return (
      <>
        <>
          {isEditModeOn && (
            <div
              className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
              <TermsAndSubscription />
            </div>
          )}
        </>
        {!storeData?.VIPOnly && hasSubscription && (
          <div
            className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
            <TermsAndSubscription />
          </div>
        )}
      </>
    );
  },
};
