import {
  marginFields,
  MarginProps,
} from '../../../page-builder/puck/reusable-props/margin';
import { ComponentConfig } from '@measured/puck';
import { CartComponent } from './Cart';

export const Cart: ComponentConfig<MarginProps> = {
  fields: {
    ...marginFields,
  },
  defaultProps: {
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({ margin }) => {
    return <CartComponent margin={margin} />;
  },
};
