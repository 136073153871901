import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ComponentConfig, DropZone, usePuck, FieldLabel } from '@measured/puck';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { formatPrice } from '../../../../page-builder/utils/formatPrice';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import {
  marginFields,
  MarginProps,
} from '../../../../page-builder/puck/reusable-props/margin';
import {
  paddingFields,
  PaddingProps,
} from '../../../../page-builder/puck/reusable-props/padding';
import { viewPorts } from '../../../utils/helper';

export type AccordionProps = {
  accordionOpenText: string;
  accordionCloseText: string;
  showTotal: boolean;
  backgroundColor: string;
} & MarginProps &
  PaddingProps;

export const Accordion: ComponentConfig<AccordionProps> = {
  label: 'Mobile Drop Down',
  fields: {
    backgroundColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel label="Background Color">
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    accordionOpenText: {
      type: 'text',
      label: 'Accordion Open Text',
    },
    accordionCloseText: {
      type: 'text',
      label: 'Accordion Close Text',
    },
    showTotal: {
      type: 'radio',
      label: 'Show Grand Total',
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    ...marginFields,
    ...paddingFields,
  },
  defaultProps: {
    backgroundColor: 'white',
    accordionOpenText: 'Accordion Open Text',
    accordionCloseText: 'Accordion Close Text',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    showTotal: true,
  },
  render: ({
    accordionOpenText,
    accordionCloseText,
    margin,
    padding,
    backgroundColor,
    showTotal,
  }) => {
    const { appState } = usePuck();
    const [searchParams] = useSearchParams();

    const isMobile = useAppSelector(state => state.pathConfig.isMobile);
    const cartData = useAppSelector(state => state.cart.cartData);
    const selectedShippingMethod = useAppSelector(
      state => state.checkout.selectedShippingMethod,
    );
    const currentPuckViewportWidth = appState?.ui?.viewports?.current?.width;
    const isMobilePreview =
      searchParams.get('editMode') === 'true'
        ? currentPuckViewportWidth < viewPorts.tablet
        : isMobile;

    const [showOrderSummary, setShowOrderSummary] = useState(true);
    useEffect(() => {
      if (isMobilePreview) {
        setShowOrderSummary(false);
      }
    }, [isMobilePreview]);

    return (
      <div
        className={`max-w-2xl w-full lg:max-w-none 
          ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
          ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
          ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} 
          ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
          ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
          ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right} `}
        style={{ backgroundColor }}>
        {isMobilePreview && (
          <div
            className="flex w-full justify-between items-center"
            onClick={() => setShowOrderSummary(!showOrderSummary)}>
            <button
              type="button"
              className="py-4 px-4 w-full text-lg font-light text-page_builder_theme flex items-center justify-start font-14">
              <p>
                {!showOrderSummary ? accordionOpenText : accordionCloseText}
              </p>
              <ChevronDownIcon
                className={`h-5 w-5 ml-2 transition-transform duration-200 ${
                  showOrderSummary ? 'transform rotate-180' : ''
                }`}
                aria-hidden="true"
              />
            </button>
            {showTotal && (
              <dd className="text-base ml-auto">
                $
                {Number(formatPrice(cartData?.TotalAmount)) +
                  Number(
                    formatPrice(
                      selectedShippingMethod?.Price ||
                        cartData?.Fees?.shipping?.OriginalCost,
                    ),
                  )}
              </dd>
            )}
          </div>
        )}

        {(showOrderSummary || !isMobilePreview) && (
          <>
            <div className={`overflow-hidden transition-all ease-in-out`}>
              <DropZone zone={`Accordion`} />
            </div>
          </>
        )}
      </div>
    );
  },
};
