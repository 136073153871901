import { baseService } from '../../page-builder/api';
import { Shop } from '../../page-builder/interface/storeInterface';
import { commonError } from '../../page-builder/api/constant';
import { AxiosError } from 'axios';

class StorefrontService {
  getStoreDetails = async (shopId: string) => {
    try {
      const response = await baseService.get<Shop>(`/store/details/${shopId}`);
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
}

const storefrontService = new StorefrontService();
export default storefrontService;
