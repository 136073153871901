import { createSlice } from '@reduxjs/toolkit';
import { IInvitation } from '../../page-builder/interface/userInterface';

export interface IInvitationConfig {
  myInvitesList: Array<IInvitation>;
}

const initialState: IInvitationConfig = {
  myInvitesList: [],
};

export const invitationSlice = createSlice({
  name: 'invitationConfig',
  initialState,
  reducers: {
    setMyInvitationDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setMyInvitationDetails } = invitationSlice.actions;

export default invitationSlice.reducer;
