import { ComponentConfig } from '@measured/puck';
import { Item } from '../../../page-builder/interface/checkoutInterface';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux-setup/store';
import { UpSellVariant } from './UpSellVariant';

export type UpSellProps = MarginProps;

export const UpSell: ComponentConfig<UpSellProps> = {
  label: 'Up Sell',
  fields: {
    ...marginFields,
  },
  defaultProps: {
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({ margin }) => {
    const preUpSellProducts = useSelector(
      (state: RootState) => state.preUpSell.preUpsellData,
    );
    if (!preUpSellProducts || !preUpSellProducts.length) return <></>;
    return (
      <div
        className={`overflow-hidden transition-all ease-in-out ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} `}>
        <ul className="flex flex-col gap-y-1">
          {preUpSellProducts.map((upSell: Item, index: number) => (
            <div
              key={index}
              className="!rounded-lg !mb-4 !border-dashed border !border-black !shadow-lg">
              <UpSellVariant variant={upSell} />
            </div>
          ))}
        </ul>
      </div>
    );
  },
};
