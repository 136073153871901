import { createSlice } from '@reduxjs/toolkit';
import { Shop } from '../../../page-builder/interface/storeInterface';

export interface StoreState {
  storeData: Shop;
}

const initialState: StoreState = {
  storeData: {
    CheckoutURL: '',
    CustomerServiceNumber: '',
    CustomerServiceEmail: '',
    MetaPixelID: '',
    Name: '',
    PaymentGateway: '',
    PublicPaymentToken: '',
    ShopID: '',
    StoreID: '',
    ShopURL: '',
    StoreDescription: '',
    VIPOnly: false,
    CustomerReviews: [
      {
        id: 0,
        name: '',
        review: '',
      },
    ],
    Timer: {
      interval: 0,
      saleText: '',
      bodyText: '',
    },
  },
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreData(state, action) {
      return { ...state, storeData: action.payload };
    },
  },
});

export const { setStoreData } = storeSlice.actions;

export default storeSlice.reducer;
