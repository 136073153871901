import { useState, useEffect } from 'react';
import { ComponentConfig } from '@measured/puck';
import { DropZone } from '@measured/puck';
import { CartTotal } from '../../../../page-builder/components/cart/cart-total/CartTotal';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import './styles.scss';
import {
  fontField,
  FontProps,
} from '../../../../page-builder/puck/reusable-props/font';

export type StandardLayoutProps = {
  border: boolean;
  bgColor: string;
  borderColor: string;
  orderSummaryText: string;
  orderSummaryTextColor: string;
  orderSummaryCartTotal: boolean;
} & FontProps;

export const StandardLayout: ComponentConfig<StandardLayoutProps> = {
  label: 'Standard Layout',
  fields: {
    border: {
      type: 'radio',
      label: 'Border',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
    },
    borderColor: {
      type: 'text',
      label: 'Border Color',
    },
    bgColor: {
      type: 'custom',
      label: 'Background Color',
      render: ({ value, onChange }) => (
        <FieldLabel label="Background Color">
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    orderSummaryText: {
      type: 'text',
      label: 'Order Summary Text',
    },
    orderSummaryTextColor: {
      type: 'custom',
      label: 'Summary Text Color',
      render: ({ value, onChange }) => (
        <FieldLabel label="Text Color">
          <SketchPicker
            color={value || '#000'}
            onChange={(color: { hex: string }) => onChange(color.hex)}
          />
        </FieldLabel>
      ),
    },
    orderSummaryCartTotal: {
      type: 'radio',
      label: 'Mobile Summary Cart Total',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
    },
    ...fontField,
  },
  defaultProps: {
    border: true,
    borderColor: '#E5E5E5',
    bgColor: '#F9F9F9',
    orderSummaryCartTotal: true,
    orderSummaryTextColor: '#000',
    orderSummaryText: 'Show order summary',
    font: {
      size: 'text-sm',
      weight: 'font-bold',
    },
  },
  render: ({
    border,
    bgColor,
    borderColor,
    orderSummaryText,
    orderSummaryCartTotal,
    orderSummaryTextColor,
  }) => {
    const [dropDown, setDropDown] = useState(false);
    const [total, setTotal] = useState(true);
    const toggleDropZone = () => setDropDown(!dropDown);

    useEffect(() => {
      const handleResize = () => {
        setDropDown(window.innerWidth >= 1024);
        setTotal(window.innerWidth <= 1024);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <>
        <div
          className={`split-layout-grid ${border ? 'lg:border-t-2' : ''} mx-auto max-w-2xl lg:max-w-none`}
          style={{
            borderColor: borderColor,
          }}>
          <div
            className={`split-layout-child mx-auto grid gap-x-8 grid-cols-1 lg-min:grid-cols-[1fr_0.8fr] grid-rows-[min-content_1fr] sm:max-w-8xl lg:max-w-6xl xl:max-w-6xl pb-14 lg:px-8`}
            style={{ width: '100%' }}>
            <div className="header-dropdzone px-4">
              <DropZone
                zone="top"
                allow={['Header', 'HeaderCheckout', 'SimplePageHeader']}
              />
            </div>
            <div
              className={`split-layout-child md:row-span-2 ${border ? 'lg-max:border-l-0 border-l-2' : ''} px-4 py-4`}
              style={{
                backgroundColor: bgColor,
              }}>
              <div className="flex justify-between">
                <button
                  type="button"
                  className={`flex items-center display-on-mobile-only`}
                  style={{ color: orderSummaryTextColor }}
                  onClick={toggleDropZone}>
                  {orderSummaryText}
                  <ChevronDownIcon
                    className={`h-5 w-5 ml-2 transition-transform duration-200 ${
                      dropDown ? 'transform rotate-180' : ''
                    }`}
                    aria-hidden="true"
                  />
                </button>
                {total && orderSummaryCartTotal && <CartTotal />}
              </div>
              {dropDown && <DropZone zone="right" />}
            </div>
            <div className="px-4">
              <DropZone zone="left" />
            </div>
          </div>
        </div>
      </>
    );
  },
};
