import './App.scss';
import '@measured/puck/puck.css';
import { CheckoutRouter } from './router/checkout-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'shared-components';
import { persistor, store } from 'shared-components';
import { Toaster } from 'shared-components';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CheckoutRouter />
          <Toaster />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
