import Editor from '@monaco-editor/react';
import PopUpModal from '../../../components/common/popup';

interface MonacoEditorProps {
  handleEditorChange: (_value: string | undefined) => void;
  value: string;
  isPopupOpen: boolean;
  setPopupOpen: (_value: boolean) => void;
  language?: string;
  handleSubmit: () => void;
}
export const MonacoEditor = ({
  value,
  handleEditorChange,
  isPopupOpen,
  setPopupOpen,
  language,
  handleSubmit,
}: MonacoEditorProps) => {
  return (
    <PopUpModal
      heading="Code Editor"
      open={isPopupOpen}
      handleClose={() => {
        setPopupOpen(false);
      }}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Close',
          buttonFunction: () => {
            setPopupOpen(false);
          },
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Save',
          buttonFunction: () => {
            setPopupOpen(false);
            handleSubmit();
          },
        },
      ]}>
      <div className="popup-section-preview-table">
        <div className="popup-table">
          <Editor
            height="40vh"
            width="60vw"
            theme="vs-dark"
            language={language ? language : 'javascript'}
            defaultValue={value}
            onChange={handleEditorChange}
          />
        </div>
      </div>
    </PopUpModal>
  );
};
