import { FieldLabel, type Config } from '@measured/puck';
import { Columns, ColumnsProps } from '../components/blocks/Columns';
import { Flex, FlexProps } from '../components/blocks/Flex';
import {
  VerticalSpace,
  VerticalSpaceProps,
} from '../components/blocks/VerticalSpace';
import { Container, ContainerProps } from '../components/blocks/Container';
import {
  CheckoutHeader as HeaderCheckout,
  CheckoutHeaderProps,
} from '../components/page-headers/CheckoutHeader';
import {
  SimplePageHeader,
  SimplePageHeaderProps,
} from '../components/page-headers/SimplePageHeader';
import { ShippingForm } from '../components/address/ShippingForm';
import {
  BillingForm,
  BillingFormProps,
} from '../components/address/BillingForm';
import { TextField, TextFieldProps } from '../components/text-field/TextField';
import {
  ContactInfo,
  ContactInfoProps,
} from '../components/contact-info/ContactInfo';
import { fontFamilies } from '../puck/reusable-props/options';
import { Timer, TimerProps } from '../components/timer/Timer';
import {
  ShippingMethodSelect,
  ShippingMethodSelectProps,
} from '../components/shipping-method/ShippingMethod';
import { Cart } from '../components/cart/index';
import { Payment, PaymentProps } from '../components/payment/Payment';
import {
  SingleStepButtonSectionProps,
  SingleStepButtonSection as ConfirmPurchase,
} from '../components/button-section/SingleStepButtonSection';
import {
  TermsAndSub,
  TermsAndSubProps,
} from '../components/terms-and-subscription';
import { Accordion, AccordionProps } from '../components/blocks/Accordion';
import { UpSell, UpSellProps } from '../components/upsell/Upsell';
import {
  CustomerReviews,
  CustomerReviewsProps,
} from '../components/customer-reviews/CustomerReviews';
import {
  OrderDetails,
  OrderDetailsProps,
} from '../components/order-details/OrderDetails';
import {
  Divider,
  DividerProps,
} from '../../page-builder/components/blocks/Divider';
import { MarginProps } from './reusable-props/margin';
import {
  CustomerGuarantees,
  CustomerGuarnteesProps,
} from '../../page-builder/components/customer-guarantees/customerGuarantees';
import {
  ImageUploader,
  ImageUploaderProps,
} from '../../page-builder/components/image-uploader/image-uploader';
import { useEffect, useState } from 'react';
import { DeleteIcon } from '../../assets/icons/delete';
import { v4 as uuidv4 } from 'uuid';
import { urlRegExp } from '../../page-builder/utils/constants';
import {
  ButtonComponent as Button,
  ButtonSectionProps,
} from '../../page-builder/components/blocks/button/button';
import { MonacoEditor } from '../../page-builder/components/monaco-editor/monaco-editor';
import { EditIcon } from '../../assets/icons/edit';
import {
  TextEditor,
  TextEditorProps,
} from '../../page-builder/components/text-editor/TextEditor';
import { FormWrapper } from '../../page-builder/components/form-wrapper';
import {
  Header,
  HeaderProps,
} from '../../page-builder/components/header/header';
import {
  PromoCode,
  PromoCodeProps,
} from '../../page-builder/components/promo-code/PromoCode';
import { RGBColor, SketchPicker } from 'react-color';
import {
  VipComponentProps,
  VipComponent,
} from '../../page-builder/components/vip';
import {
  SplitLayout,
  SplitLayoutProps,
} from '../../page-builder/components/blocks/SplitLayout';
import {
  CustomHeaderProps,
  CustomHeader as HeaderCustom,
} from '../../page-builder/components/page-headers/CustomHeader';
import {
  StandardLayout,
  StandardLayoutProps,
} from '../../page-builder/components/blocks/StandardLayout';
import {
  AlwaysVip,
  AlwaysVipProps,
} from '../../page-builder/components/always-vip';
import {
  SavingCalculator,
  SavingCalculatorProps,
} from '../components/savings-calculator';
import CommonButton from '../../components/common/button';
import {
  PaymentRadio,
  PaymentRadioProps,
} from '../components/payment-radio/PaymentRadio';

interface CssData {
  id: string;
  name?: string;
  link: string;
  added?: boolean;
  codeEditor?: boolean;
}
interface ScriptData {
  id: string;
  name?: string;
  link: string;
  added?: boolean;
  codeEditor?: boolean;
}
type Props = {
  // Layouts
  SplitLayout: SplitLayoutProps;
  StandardLayout: StandardLayoutProps;
  // Blocks
  Columns: ColumnsProps;
  Flex: FlexProps;
  VerticalSpace: VerticalSpaceProps;
  Divider: DividerProps;
  Container: ContainerProps;
  // Text
  TextField: TextFieldProps;
  TextEditor: TextEditorProps;
  // Buttons
  Button: ButtonSectionProps;
  ConfirmPurchase: SingleStepButtonSectionProps;
  // Page Headers
  Header: HeaderProps;
  HeaderCustom: CustomHeaderProps;
  SimplePageHeader: SimplePageHeaderProps;
  HeaderCheckout: CheckoutHeaderProps;
  // Form Components
  ContactInfo: ContactInfoProps;
  ShippingForm: MarginProps;
  BillingForm: BillingFormProps;
  ShippingMethodSelect: ShippingMethodSelectProps;
  Payment: PaymentProps;
  // Checkout Components
  Cart: MarginProps;
  Timer: TimerProps;
  TermsAndSub: TermsAndSubProps;
  Accordion: AccordionProps;
  UpSell: UpSellProps;
  CustomerReviews: CustomerReviewsProps;
  OrderDetails: OrderDetailsProps;
  CustomerGuarantees: CustomerGuarnteesProps;
  ImageUploader: ImageUploaderProps;
  PromoCode: PromoCodeProps;
  VipComponent: VipComponentProps;
  AlwaysVip: AlwaysVipProps;
  SavingCalculator: SavingCalculatorProps;
  PaymentRadio: PaymentRadioProps;
};

export const config: Config<Props> = {
  categories: {
    Layouts: {
      components: ['SplitLayout', 'StandardLayout'],
      defaultExpanded: false,
    },
    Blocks: {
      components: [
        'Columns',
        'Flex',
        'VerticalSpace',
        'Container',
        'Accordion',
        'Divider',
      ],
      defaultExpanded: false,
    },
    Text: {
      components: ['TextField', 'TextEditor'],
      defaultExpanded: false,
    },
    Buttons: {
      components: ['Button', 'ConfirmPurchase'],
      defaultExpanded: false,
    },
    'Page Headers': {
      components: [
        'Header',
        'HeaderCheckout',
        'SimplePageHeader',
        'HeaderCustom',
      ],
      defaultExpanded: false,
    },
    'Form Components': {
      components: [
        'ContactInfo',
        'ShippingForm',
        'BillingForm',
        'ShippingMethodSelect',
        'Payment',
        'PaymentRadio',
      ],
      defaultExpanded: false,
    },
    'Checkout Components': {
      components: [
        'Cart',
        'OrderDetails',
        'PromoCode',
        'VipComponent',
        'UpSell',
        'CustomerGuarantees',
        'CustomerReviews',
        'TermsAndSub',
        'Timer',
      ],
      defaultExpanded: false,
    },
    other: {
      defaultExpanded: false,
    },
  },
  components: {
    //Layout Components
    SplitLayout,
    StandardLayout,
    // Block Components
    Columns,
    Flex,
    VerticalSpace,
    Divider,
    Container,
    Accordion,
    // Text
    TextEditor,
    TextField,
    // Buttons
    Button,
    ConfirmPurchase,
    // Page Headers
    Header,
    HeaderCustom,
    HeaderCheckout,
    SimplePageHeader,
    // Form Components
    ContactInfo,
    ShippingForm,
    BillingForm,
    ShippingMethodSelect,
    Payment,
    PaymentRadio,
    // Checkout Components
    Cart,
    PromoCode,
    OrderDetails,
    UpSell,
    CustomerReviews,
    Timer,
    TermsAndSub,
    CustomerGuarantees,
    VipComponent,
    // Other
    ImageUploader,
    AlwaysVip,
    SavingCalculator,
  },
  root: {
    fields: {
      fontFamily: {
        label: 'Font Family',
        type: 'select',
        options: fontFamilies,
      },
      themeColor: {
        type: 'custom',
        label: 'Theme Color',
        render: ({ value, onChange }) => {
          const handleChange = async (color: RGBColor) => {
            onChange(color);
            const iframe = document.getElementById(
              'preview-frame',
            ) as HTMLIFrameElement;

            if (iframe) {
              const iframeDocument = iframe.contentDocument as Document;
              const iframeBody = iframeDocument.body;

              if (iframeBody) {
                iframeBody.style.setProperty(
                  '--color-page-builder-theme',
                  `${color?.r} ${color?.g} ${color?.b}`,
                );
              }
            }
          };
          return (
            <FieldLabel label="Theme Color">
              <SketchPicker
                color={value}
                onChange={(color: { rgb: RGBColor }) =>
                  handleChange(color?.rgb)
                }
              />
            </FieldLabel>
          );
        },
      },
      errorColor: {
        type: 'custom',
        label: 'Error Color',
        render: ({ value, onChange }) => {
          return (
            <FieldLabel label="Error Color">
              <SketchPicker
                color={value}
                onChange={(color: { hex: string }) => onChange(color?.hex)}
              />
            </FieldLabel>
          );
        },
      },
      customCss: {
        type: 'custom',
        label: 'Custom CSS',
        render: ({ value, onChange }) => {
          value = value || [];
          const [link, setLink] = useState<string>('');
          const [manualCss, setManualCss] = useState<string>(
            value.find((css: CssData) => css.name === 'Manual CSS')?.link ?? '',
          );
          const [errorMessage, setErrorMessage] = useState<boolean>(false);
          const handleAddCss = (type?: string): void => {
            const newCss: CssData = {
              id: uuidv4(),
              name: '',
              link: '',
              added: true,
            };
            if (type === 'external_css') {
              const regExp = urlRegExp;
              if (link && !!regExp.test(link)) {
                setErrorMessage(false);
              } else {
                setErrorMessage(true);
                return;
              }
              newCss.link = link;
              value.push(newCss);
              onChange([...value]);
              setLink('');
            } else {
              const index = value.findIndex(
                (css: CssData) => css.name === 'Manual CSS',
              );
              if (index !== -1) {
                value[index].link = manualCss;
                value[index].added = true;
                onChange([...value]);
              } else {
                newCss.name = 'Manual CSS';
                newCss.codeEditor = true;
                newCss.link = manualCss;
                value.push(newCss);
                onChange([...value]);
              }
            }
          };
          const handleEditCss = (newValue: string): void => {
            setLink(newValue);
          };

          const handleRemoveCss = (index: number): void => {
            const cssData = value[index];
            const RemoveCss = document.getElementById(
              `custom-css-${cssData.id}`,
            );
            if (RemoveCss) {
              RemoveCss.remove();
            }
            onChange(value.filter((_: unknown, i: number) => i !== index));
          };

          const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
          const handleEditorChange = (value: string | undefined) => {
            value && setManualCss(value);
          };

          return (
            <div>
              <div className="flex justify-between items-center">
                <label className="flex justify-between text-sm font-medium text-gray-700 pagebuilder-theme">
                  Custom CSS
                </label>
                <CommonButton
                  label="Open Code Editor"
                  className="!p-2 !text-[10px]"
                  variant="secondary"
                  disabled={manualCss === '' ? false : true}
                  onClick={() => setPopupOpen(!isPopupOpen)}
                />
              </div>
              {value.map(
                (css: CssData, index: number) =>
                  css.name && (
                    <div
                      key={index}
                      className="flex p-2 pt-3 justify-between items-center">
                      <div className="block font-semibold gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {css.name}
                      </div>
                      <div className="flex gap-2">
                        <button
                          className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                          type="button"
                          onClick={() => {
                            setPopupOpen(true);
                          }}>
                          <EditIcon />
                        </button>
                        <button
                          className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                          type="button"
                          onClick={() => {
                            handleRemoveCss(index);
                            setManualCss('');
                          }}>
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  ),
              )}

              {value.map(
                (css: CssData, index: number) =>
                  !css.name && (
                    <div key={css.id} className="flex gap-3 p-1">
                      <div className="block gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {css.link}
                      </div>
                      <button
                        className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                        type="button"
                        onClick={() => handleRemoveCss(index)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  ),
              )}
              <div className="flex flex-col">
                <input
                  className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in"
                  placeholder="Place your external css link here"
                  value={link}
                  onChange={e => handleEditCss(e.target.value)}
                />
                {errorMessage && (
                  <p className="text-red-500 text-xs">Invalid External URL</p>
                )}
                <CommonButton
                  label="+ Add"
                  className="mt-4 w-full"
                  variant="secondary"
                  onClick={() => handleAddCss('external_css')}
                />
              </div>
              <MonacoEditor
                isPopupOpen={isPopupOpen}
                setPopupOpen={setPopupOpen}
                value={manualCss}
                language={'scss'}
                handleEditorChange={handleEditorChange}
                handleSubmit={() => {
                  handleAddCss('manual_css');
                }}
              />
            </div>
          );
        },
      },
      customScripts: {
        type: 'custom',
        label: 'Custom Scripts',
        render: ({ value, onChange }) => {
          value = value || [];
          const [link, setLink] = useState<string>('');
          const [manualScript, setManualScript] = useState<string>(
            value.find((script: ScriptData) => script.name === 'Manual Script')
              ?.link ?? '',
          );
          const [errorMessage, setErrorMessage] = useState<boolean>(false);
          const handleAddScript = (type?: string): void => {
            const newScript: ScriptData = {
              id: uuidv4(),
              name: '',
              link: '',
              added: true,
            };
            if (type === 'external_script') {
              const regExp = urlRegExp;
              if (link && !!regExp.test(link)) {
                setErrorMessage(false);
              } else {
                setErrorMessage(true);
                return;
              }
              newScript.link = link;
              value.push(newScript);
              onChange([...value]);
              setLink('');
            } else {
              const index = value.findIndex(
                (script: ScriptData) => script.name === 'Manual Script',
              );
              if (index !== -1) {
                value[index].link = manualScript;
                value[index].added = true;
                onChange([...value]);
              } else {
                newScript.name = 'Manual Script';
                newScript.codeEditor = true;
                newScript.link = manualScript;
                value.push(newScript);
                onChange([...value]);
              }
            }
          };
          const handleEditScript = (newValue: string): void => {
            setLink(newValue);
          };

          const handleRemoveScript = (index: number): void => {
            const scriptData = value[index];
            const RemoveScript = document.getElementById(
              `custom-script-${scriptData.id}`,
            );
            if (RemoveScript) {
              RemoveScript.remove();
            }
            onChange(value.filter((_: unknown, i: number) => i !== index));
          };

          const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
          const handleEditorChange = (value: string | undefined) => {
            value && setManualScript(value);
          };

          return (
            <div>
              <div className="flex justify-between items-center">
                <label className="flex justify-between text-sm font-medium text-gray-700 pagebuilder-theme">
                  Custom Scripts
                </label>
                <CommonButton
                  label="Open Code Editor"
                  className="!p-2 !text-[10px]"
                  variant="secondary"
                  disabled={manualScript === '' ? false : true}
                  onClick={() => setPopupOpen(!isPopupOpen)}
                />
              </div>
              {value.map(
                (script: ScriptData, index: number) =>
                  script.name && (
                    <div
                      key={index}
                      className="flex p-1 pb-3 pt-3 justify-between items-center">
                      <div className="block font-semibold gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {script.name}
                      </div>
                      <div className="flex gap-2">
                        <button
                          className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                          type="button"
                          onClick={() => {
                            setPopupOpen(true);
                          }}>
                          <EditIcon />
                        </button>
                        <button
                          className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                          type="button"
                          onClick={() => {
                            handleRemoveScript(index);
                            setManualScript('');
                          }}>
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  ),
              )}

              {value.map(
                (script: ScriptData, index: number) =>
                  !script.name && (
                    <div key={script.id} className="flex gap-3 p-1">
                      <div className="block gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {script.link}
                      </div>
                      <button
                        className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                        type="button"
                        onClick={() => handleRemoveScript(index)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  ),
              )}
              <div className="flex flex-col">
                <input
                  className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in"
                  placeholder="Place your external script link here"
                  value={link}
                  onChange={e => handleEditScript(e.target.value)}
                />
                {errorMessage && (
                  <p className="text-red-500 text-xs">Invalid External URL</p>
                )}
                <CommonButton
                  label="+ Add"
                  className="mt-4 w-full"
                  variant="secondary"
                  onClick={() => handleAddScript('external_script')}
                />
              </div>
              <MonacoEditor
                isPopupOpen={isPopupOpen}
                setPopupOpen={setPopupOpen}
                value={manualScript}
                language={'javascript'}
                handleEditorChange={handleEditorChange}
                handleSubmit={() => {
                  handleAddScript('manual_script');
                }}
              />
            </div>
          );
        },
      },
    },
    defaultProps: {
      fontFamily: fontFamilies[0].value,
      themeColor: { r: 79, g: 70, b: 229 },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
    render: ({
      children,
      fontFamily,
      errorColor,
      customCss,
      customScripts,
    }) => {
      const [width, setWidth] = useState(window.innerWidth);
      useEffect(() => {
        if (customCss) {
          customCss.map((css: CssData) => {
            if (css.name) {
              if (document.getElementById(`custom-css-${css.id}`)) {
                document.getElementById(`custom-css-${css.id}`)?.remove();
              }
              const cssElement = document.createElement('style');
              cssElement.id = `custom-css-${css.id}`;
              cssElement.textContent = `.puck-page{
              ${css.link}
            }`;
              document.head.appendChild(cssElement);
            }
          });
        }
      }, [customCss]);

      useEffect(() => {
        if (customScripts) {
          customScripts.map((script: ScriptData) => {
            if (script.name === 'Manual Script') {
              document.getElementById(`custom-script-${script.id}`)?.remove();
            }
            if (
              script.added &&
              !document.getElementById(`custom-script-${script.id}`)
            ) {
              const scriptElement = document.createElement('script');
              scriptElement.id = `custom-script-${script.id}`;
              !script?.codeEditor
                ? (scriptElement.src = script.link)
                : (scriptElement.textContent = script.link);
              document.body.appendChild(scriptElement);
            }
          });
        }
      }, [customScripts]);
      useEffect(() => {
        const handleResize = () => {
          setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      return (
        <FormWrapper>
          <div
            className={`puck-page ${width <= 640 ? 'responsive-font' : ''}`}
            style={
              {
                height: 'calc(100vh - 0px)',
                overflow: 'auto',
                '--error-color': errorColor ?? '#FF5A50',
                fontFamily: fontFamily,
              } as React.CSSProperties
            }>
            {customCss &&
              customCss.map(
                (css: CssData, index: number) =>
                  !css.name && (
                    <link
                      key={index}
                      rel="stylesheet"
                      type="text/css"
                      href={css.link}></link>
                  ),
              )}
            {children}
          </div>
        </FormWrapper>
      );
    },
  },
};

export default config;
