import { ButtonHTMLAttributes, FC } from 'react';
import './style.scss';
import { CircularProgress } from '@mui/material';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  variant?: 'phoenix' | 'secondary';
  className?: string;
  loading?: boolean;
}

const Button: FC<IButtonProps> = ({
  variant,
  label,
  className = '',
  loading = false,
  ...rest
}) => {
  return (
    <button
      className={`button_field_wrapper ${className} ${variant ? 'button_' + variant : ''}`}
      {...rest}>
      {loading ? (
        <CircularProgress className="spinner" color="inherit" />
      ) : (
        label
      )}
    </button>
  );
};
export default Button;
