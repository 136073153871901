import { useSearchParams } from 'react-router-dom';
import { Render } from '../../../page-builder/components/render/render';
import { usePreload } from '../../../page-builder/hooks/usePreload';
import { LoadingPage } from '../../../page-builder/components/shared/LoadingPage';
import { Config, Data } from '@measured/puck';
import HomeEdit from '../edit';
import { ICheckoutPage } from '../../../page-builder/interface/pageInterface';

interface IProps {
  data: Data;
  config: Config;
  checkoutPage: ICheckoutPage | null;
  onSuccesCallBack: () => void;
}

const HomePage = ({ data, config, checkoutPage, onSuccesCallBack }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // render page
  const { isLoading, error } = usePreload();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  if (searchParams.get('editMode') === 'true') {
    // if editMode is true, render the editor
    return (
      <HomeEdit
        data={data}
        config={config}
        checkoutPage={checkoutPage}
        onSuccesCallBack={onSuccesCallBack}
      />
    );
  }

  if (!data) {
    return (
      <>
        configure the components
        <button
          onClick={() => {
            setSearchParams({
              ...Object.fromEntries(searchParams.entries()),
              editMode: 'true',
            });
          }}>
          here
        </button>
      </>
    );
  }

  return <Render config={config} data={data} />;
};

export default HomePage;
