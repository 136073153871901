import InfiniteScroll from 'react-infinite-scroll-component';
import { ImageInput } from './index';
import './style.scss';
import checkoutService from '../../../page-builder/api/checkoutService';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { DeleteIcon } from '../../../assets/icons/delete';
import './style.scss';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICheckoutPage } from '../../interface/pageInterface';
import globalPageService from '../../services/pageService';
import PopUpModal from '../../../components/common/popup';
import Checkbox from '../../../components/common/checkbox';
import { Loader } from '../../../components/common/loader';
export interface Iprops {
  handleSubmit: (_selectedImage: IAsset) => void;
  setPopupOpen: (_open: boolean) => void;
  image: IAsset;
  isPopupOpen: boolean;
}
export type IpageSearch = {
  StoreID: string;
  Descending: boolean;
  Limit: number;
  OrderBy: string;
  Page: number;
  SearchFields: object;
};
const ImagePopup = ({
  handleSubmit,
  setPopupOpen,
  image,
  isPopupOpen,
}: Iprops) => {
  const search: IpageSearch = {
    StoreID: '',
    Descending: false,
    Limit: 25,
    OrderBy: 'Filename',
    Page: 0,
    SearchFields: {},
  };
  const [pagesSearch, setPagesSearch] = useState<IpageSearch>(search);
  const [searchValue, setSearchValue] = useState('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [defaultImageList, setDefaultImageList] = useState<IAsset[]>([]);

  const [selectedImage, setSelectedImage] = useState<IAsset>(image);

  const [imageList, setImageList] = useState<IAsset[]>([]);
  const handleSelection = () => {
    handleSubmit(selectedImage);
    setPopupOpen(false);
  };
  const [checkoutPage, setCheckoutPage] = useState<ICheckoutPage | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const storeID = searchParams.get('storeID') || '';
  const pageID = searchParams.get('pageID') || '';

  const changeImageSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    image: IAsset,
  ) => {
    if (event.target.checked) {
      setSelectedImage(image);
    }
  };

  const getImageLists = async () => {
    try {
      if (!pagesSearch.StoreID) return;
      setLoading(true);
      const response = await checkoutService.getAssets(pagesSearch);
      setLoading(false);
      if (response?.status === 200 && response?.data?.Result) {
        setImageList(prev => [...prev, ...response.data.Result]);
        setDefaultImageList(prev => [...prev, ...response.data.Result]);
        if (response?.data?.TotalCount > pagesSearch.Limit * pagesSearch.Page) {
          setHasMore(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('ERROR IN getImageLists', error);
    }
  };

  const fetchMoreData = (): void => {
    if (hasMore) {
      setPagesSearch(prevPage => ({
        ...prevPage,
        Page: prevPage.Page + 1,
      }));
    }
  };

  const deleteImage = async (image: IAsset) => {
    try {
      const payLoad = {
        ID: image.ID,
        StoreID: image.StoreID,
        Filename: image.Filename,
      };
      setLoading(true);
      const response = await checkoutService.deleteAsset(payLoad);
      setLoading(false);
      if (response.status === 200) {
        const updatedImageList = defaultImageList.filter(
          (img: IAsset) => img.ID !== image.ID,
        );
        setDefaultImageList(updatedImageList);
        setImageList(updatedImageList);
      }
    } catch (error) {
      setLoading(false);
      console.error('ERROR IN deleteImage', error);
    }
  };

  const getPageDeatils = async () => {
    setLoading(true);
    const res = await globalPageService.getPage(storeID, pageID);
    setLoading(false);
    if (res?.status === 200 && res?.data) {
      setCheckoutPage(res?.data);
      setPagesSearch(pre => ({ ...pre, StoreID: res?.data?.StoreID }));
    }
  };

  useEffect(() => {
    getImageLists();
  }, [pagesSearch]);

  useEffect(() => {
    setImageList(
      defaultImageList.filter((image: IAsset) =>
        image.Filename?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    );
  }, [searchValue]);

  useEffect(() => {
    getPageDeatils();
  }, []);

  return (
    <PopUpModal
      open={isPopupOpen}
      handleClose={() => setPopupOpen(false)}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => setPopupOpen(false),
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Done',
          buttonFunction: handleSelection,
        },
      ]}
      heading={'Image Selector'}>
      <div className="popup-section !w-[100%] relative">
        <Loader loading={loading} />
        <div className=" flex justify-between items-center">
          <div className="bg-slate-400 flex items-center w-[40%] p-[4px] rounded-md mb-2">
            <SearchIcon className="text-gray-500" />
            <input
              className="!bg-transparent w-[40%] p-2 rounded-md border !border-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent font-medium"
              value={searchValue}
              placeholder="Search"
              onChange={e => {
                setSearchValue(
                  e.target.value.startsWith(' ')
                    ? e.target.value.trim()
                    : e.target.value,
                );
              }}
            />
          </div>
          <div>
            {selectedImage?.ID && (
              <div>
                <button
                  className="bg-transparent text-red-500 border border-red-500 rounded-md px-2 py-1 mr-3 hover:bg-red-500 hover:text-white"
                  onClick={() => {
                    deleteImage(selectedImage);
                    setSelectedImage({
                      Filename: '',
                      BlobURL: '',
                      StoreID: '',
                      ID: '',
                      Version: 0,
                    });
                  }}>
                  <DeleteIcon />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center  p-[36px] border border-gray-400 border-dashed bg-white-50 cursor-pointer rounded-md">
          {!!checkoutPage?.StoreID && (
            <ImageInput
              setDefaultImageList={setDefaultImageList}
              setImageList={setImageList}
              setPagesSearch={setPagesSearch}
              checkoutPage={checkoutPage}
              setLoading={setLoading}
            />
          )}
        </div>
        <div className="product-section-wrapper">
          {imageList?.length > 0 && (
            <InfiniteScroll
              dataLength={imageList.length}
              next={() => fetchMoreData()}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              loader={<></>}>
              <div className="image-selector-container">
                {imageList.map((image, _index) => {
                  return (
                    <div
                      key={_index}
                      className="w-full p-1 hover:bg-slate-500 rounded cursor-pointer"
                      onClick={() => {
                        if (image?.ID == selectedImage.ID) {
                          setSelectedImage({
                            Filename: '',
                            BlobURL: '',
                            StoreID: '',
                            ID: '',
                            Version: 0,
                          });
                        } else {
                          setSelectedImage(image);
                        }
                      }}>
                      <div className="relative p-3">
                        <Checkbox
                          className="!absolute top-[14px] left-[14px] !p-0"
                          checked={image?.ID == selectedImage.ID ? true : false}
                          onChange={event => changeImageSelection(event, image)}
                        />
                        <img
                          className="aspect-square object-cover rounded-md"
                          src={`${image?.BlobURL}`}
                          alt=""
                        />
                      </div>
                      <div className="font-12 whitespace-nowrap text-ellipsis overflow-hidden text-center pb-3">
                        {image.Filename}
                      </div>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </PopUpModal>
  );
};

export default ImagePopup;
