import { AxiosError } from 'axios';
import {
  commonError,
  createPreUpSell,
  getCheckoutPagesEP,
  getPage,
  searchRiskEP,
} from '../services/constants';
import { basePortalService } from '../api/portalService';
import { IPageSearchBody, IPreUpSellCreate } from '../interface/pageInterface';

class PageService {
  createupSell = async (body: IPageSearchBody) => {
    try {
      const response = await basePortalService.post(createPreUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  searchPages = async (body: IPageSearchBody) => {
    try {
      const response = await basePortalService.post(getCheckoutPagesEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getPage = async (store_id: string, checkout_page_id: string) => {
    try {
      const response = await basePortalService.get(
        getPage(store_id, checkout_page_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class PageMockService {
  createupSell = async (body: IPageSearchBody) => {
    try {
      const response = await basePortalService.post<IPreUpSellCreate>(
        searchRiskEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  searchPages = async (body: IPageSearchBody) => {
    try {
      const response = await basePortalService.post(getCheckoutPagesEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getPage = async (store_id: string, checkout_page_id: string) => {
    try {
      const response = await basePortalService.get(
        getPage(store_id, checkout_page_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalPageService: PageService | PageMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new PageMockService()
    : new PageService();

export default globalPageService;
