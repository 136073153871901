import React, { useCallback, useEffect } from 'react';
import { defaultValues as defaultValueExpectCardData } from '../../../page-builder/utils/constants';
import { useForm, FormProvider } from 'react-hook-form';
import { FormData } from '../../../page-builder/interface/checkoutInterface';
import { useAppSelector } from '../../hooks/reduxHooks';
import { v4 as uuidv4 } from 'uuid';

import eventEmitter from '../../../page-builder/utils/events';

interface FormWrapperProps {
  children: React.ReactNode;
  singleStep?: boolean | undefined;
}

export const FormWrapper: React.FC<FormWrapperProps> = ({ children }) => {
  const cartData = useAppSelector(state => state.cart?.cartData);
  const storeData = useAppSelector(state => state.store.storeData);
  const isEmpty = useCallback((obj: object) => {
    return obj ? Object.keys(obj).length === 0 : true;
  }, []);

  const onSubmit = (data: FormData) => {
    const totalPrice = cartData?.TotalAmount;
    const customerPhoneNumber = data.CustomerData.PhoneNumber
      ? `${data.phone_code} ${data.CustomerData.PhoneNumber}`
      : null;
    const formValues = {
      ...data,
      HasSubscription: cartData?.HasSubscription,
      ShopID: storeData?.ShopID,
      ID: uuidv4(),
      TotalAmount: `${totalPrice}`,
      CustomerData: {
        Email: data.CustomerData.Email,
        PhoneNumber: customerPhoneNumber,
      },
      Shipping: data.Shipping,
      ShippingMethodID: data.ShippingMethodID,
      BillingAddress:
        data.billing_same_as_shipping || isEmpty(data.BillingAddress)
          ? {
              Address1: data.Shipping.Address1,
              Address2: data.Shipping.Address2,
              City: data.Shipping.City,
              CountryCode: data.Shipping.CountryCode,
              FirstName: data.Shipping.FirstName,
              LastName: data.Shipping.LastName,
              Organization: data.Shipping.Organization,
              ProvinceCode: data.Shipping.ProvinceCode,
              Zip: data.Shipping.Zip,
            }
          : { ...data.BillingAddress },
    };
    eventEmitter.emit('completePurchase', formValues);
  };

  const getDefaultFormValues = useCallback((): FormData => {
    const defaultValues: FormData = {
      ...defaultValueExpectCardData,
      CardInfo: {
        Number: '',
        Exp: '',
        CVV: '',
      },
    };
    if (
      cartData &&
      cartData.UpdatedAt &&
      new Date().getTime() - new Date(cartData.UpdatedAt).getTime() <
        24 * 60 * 60 * 1000
    ) {
      return {
        ...defaultValues,
        CustomerData: {
          ...defaultValues.CustomerData,
          Email: cartData?.Email || '',
          PhoneNumber:
            cartData?.PhoneNumber && cartData.PhoneNumber.length >= 10
              ? cartData.PhoneNumber.substring(cartData.PhoneNumber.length - 10)
              : '',
        },
        Shipping: {
          ...defaultValues.Shipping,
          Address1: cartData?.ShippingAddress?.Address1 || '',
          Address2: cartData?.ShippingAddress?.Address2 || '',
          City: cartData?.ShippingAddress?.City || '',
          CountryCode: cartData?.ShippingAddress?.CountryCode || '',
          FirstName: cartData?.ShippingAddress?.FirstName || '',
          LastName: cartData?.ShippingAddress?.LastName || '',
          Organization: cartData?.ShippingAddress?.Organization || '',
          ProvinceCode: cartData?.ShippingAddress?.ProvinceCode || '',
          Zip: cartData?.ShippingAddress?.Zip || '',
        },
        ShippingMethodID: cartData?.ShippingMethodID || '',
      };
    }
    return defaultValues;
  }, [cartData]);

  const methods = useForm<FormData>({
    defaultValues: getDefaultFormValues(),
    mode: 'onChange',
  });

  useEffect(() => {
    if (cartData) {
      const fieldsToUpdate: (keyof FormData)[] = [
        'CustomerData',
        'Shipping',
        'ShippingMethodID',
      ];
      fieldsToUpdate.forEach(field => {
        methods.setValue(field, getDefaultFormValues()[field], {
          shouldValidate: false,
        });
      });
    }
  }, [cartData, methods, getDefaultFormValues]);
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          {children}
        </form>
      </FormProvider>
    </>
  );
};
