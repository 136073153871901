import React, { useEffect, useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import './style.scss';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { VectorIcon } from '../../../assets/icons/vector';

interface Option<T> {
  label: T;
  value: T;
  [_: string]: T;
}

interface SelectProps<T> {
  options: Option<T>[];
  valueKey?: keyof Option<T>;
  labelKey?: keyof Option<T>;
  value: T | null;
  placeholder?: string;
  onChange: (_value: T) => void;
  disabled?: boolean;
  error?: string;
  className?: string;
  label?: string;
}

const Select = <T,>({
  options,
  valueKey = 'value',
  labelKey = 'label',
  value,
  disabled = false,
  placeholder = '',
  className = '',
  error = '',
  label = '',
  onChange,
}: SelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const selected = options.find(item => item[valueKey] === value);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={`common_select_container ${className}`}>
      {label && <p className="common_label_text">{label}</p>}
      <div
        className={`toggle_button_wrapper ${disabled}`}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}>
        <span
          className={`toggle_button_text ${selected ? 'selected_label' : 'placeholder'}`}>
          <>{selected ? selected[labelKey] : placeholder}</>
        </span>
        <VectorIcon />
      </div>
      <Popper
        className="select_popper_menu z1"
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}>
            <div className="menu_wrapper">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className="menu_list"
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}>
                  {options.map((option, i) => (
                    <MenuItem
                      key={i}
                      className="menu_item"
                      onClick={() => {
                        handleClose();
                        onChange(option[valueKey]);
                      }}>
                      <>{option[labelKey]}</>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
      {error && <p className="common_error_text">{error}</p>}
    </div>
  );
};

export default Select;

interface ISelectControlProps<T>
  extends Omit<SelectProps<T>, 'value' | 'onChange' | 'ref'> {
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}
export const SelectControl = <T,>({
  name,
  rules,
  ...rest
}: ISelectControlProps<T>) => {
  return (
    <Controller
      name={name}
      render={({ field: { ...restField }, fieldState: { error } }) => {
        return <Select {...rest} {...restField} error={error?.message} />;
      }}
      rules={rules}
    />
  );
};
