import React, { useState } from 'react';
import { setIsTermsAccepted } from '../../redux/slices/termsAndSubSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';

export const TermsAndSubscription: React.FC = () => {
  const dispatch = useAppDispatch();
  const isTermsAccepted = useAppSelector(
    state => state.termsAndSub.isTermsAccepted,
  );
  const storeData = useAppSelector(state => state.store.storeData);

  const [viewTerms, setViewTerms] = useState<boolean>(false);

  //TODO add styles

  return (
    <React.Fragment>
      <div className="py-2">
        <fieldset>
          <legend className="sr-only">Notifications</legend>
          <div className="space-y-5">
            <div className="flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="terms_of_service"
                  type="checkbox"
                  className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600`}
                  onChange={() => {
                    dispatch(setIsTermsAccepted(!isTermsAccepted));
                  }}
                  checked={isTermsAccepted}
                />
              </div>
              <div className="checkbox-text ml-3 leading-6">
                <p>
                  I acknowledge that I have read the{' '}
                  <a
                    // id={styles['navigation-links']}
                    type="button"
                    className="checkbox-text text-indigo-600 hover:text-indigo-500"
                    href={`${storeData?.ShopURL}/pages/privacy-policy`}>
                    Privacy Policy
                  </a>{' '}
                  and the{' '}
                  <a
                    // id={styles['navigation-links']}
                    type="button"
                    className="checkbox-text text-indigo-600 hover:text-indigo-500"
                    href={`${storeData?.ShopURL}/pages/terms-of-service`}>
                    Terms of Service
                  </a>
                  {'. '}I agree to the Auto-Renewal Payment Authorization above.
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <button
        // id={styles['view-terms']}
        className="hover:text-indigo-500 text-xs"
        type="button"
        onClick={() => setViewTerms(!viewTerms)}>
        + Learn More
      </button>
      <div
        className={`mx-auto max-w-2xl text-center transition-all duration-500 ease-in-out ${
          viewTerms ? 'max-h-screen' : 'max-h-0'
        } overflow-hidden`}>
        <p className="mt-3 vip-terms-text text-left leading-5 text-black">
          {storeData?.TermsAndSubscription?.terms_1}
        </p>
        <p className="mt-3 vip-terms-text text-left leading-5 text-black">
          {storeData?.TermsAndSubscription?.terms_2}
        </p>
        <p className="mt-3 vip-terms-text text-left leading-5 text-black">
          {storeData?.TermsAndSubscription?.terms_3}
          <a
            // id={styles['navigation-links']}
            href={`${storeData?.ShopURL}/pages/vip-unsubscribe`}
            className="vip-terms-text text-indigo-600 hover:text-indigo-500">
            Cancel Membership
          </a>{' '}
          {storeData?.TermsAndSubscription?.terms_4}
        </p>
      </div>
    </React.Fragment>
  );
};
