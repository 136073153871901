import { FieldLabel, type Config } from '@measured/puck';
import { Columns, ColumnsProps } from '../components/blocks/Columns';
import { Flex, FlexProps } from '../components/blocks/Flex';
import {
  VerticalSpace,
  VerticalSpaceProps,
} from '../components/blocks/VerticalSpace';
import { Container, ContainerProps } from '../components/blocks/Container';
import { TextField, TextFieldProps } from '../components/text-field/TextField';
import { fontFamilies } from '../puck/reusable-props/options';
import { Accordion, AccordionProps } from '../components/blocks/Accordion';
import {
  Divider,
  DividerProps,
} from '../../page-builder/components/blocks/Divider';
import { useEffect, useState } from 'react';
import { DeleteIcon } from '../../assets/icons/delete';
import { v4 as uuidv4 } from 'uuid';
import { urlRegExp } from '../../page-builder/utils/constants';
import {
  ButtonComponent as Button,
  ButtonSectionProps,
} from '../../page-builder/components/blocks/button/button';
import { MonacoEditor } from '../../page-builder/components/monaco-editor/monaco-editor';
import { EditIcon } from '../../assets/icons/edit';

import {
  Timer,
  TimerProps,
} from '../../page-builder/components/post-upsell/timer';
import {
  Header,
  HeaderProps,
} from '../../page-builder/components/post-upsell/header/Header';
import {
  Product,
  ProductProps,
} from '../../page-builder/components/post-upsell/product-varient';
import {
  UpsellDeal,
  UpsellDealProps,
} from '../../page-builder/components/post-upsell/deal';
import {
  NextButton,
  NextButtonProps,
} from '../../page-builder/components/post-upsell/next-button/next-button';
import { RGBColor, SketchPicker } from 'react-color';
import CommonButton from '../../components/common/button';
interface CssData {
  id: string;
  name?: string;
  link: string;
  added?: boolean;
  codeEditor?: boolean;
}
interface ScriptData {
  id: string;
  name?: string;
  link: string;
  added?: boolean;
  codeEditor?: boolean;
}

type Props = {
  // Typology Components
  TextField: TextFieldProps;
  Columns: ColumnsProps;
  Flex: FlexProps;
  VerticalSpace: VerticalSpaceProps;
  Divider: DividerProps;
  Container: ContainerProps;
  Button: ButtonSectionProps;
  Accordion: AccordionProps;
  Timer: TimerProps;
  Header: HeaderProps;
  Product: ProductProps;
  UpsellDeal: UpsellDealProps;
  NextButton: NextButtonProps;
};

export const PostUpsellConfig: Config<Props> = {
  categories: {
    Layouts: {
      components: [
        'Columns',
        'Flex',
        'VerticalSpace',
        'Container',
        'Accordion',
        'Divider',
        'Button',
      ],
    },
    PostUpsellTimer: {
      components: ['Timer'],
    },
    Header: {
      components: ['Header'],
    },
    Product: {
      components: ['Product'],
    },
    DealButton: {
      components: ['UpsellDeal'],
    },
    NextPostUpSellProduct: {
      components: ['NextButton'],
    },
  },
  components: {
    TextField,
    Columns,
    Flex,
    VerticalSpace,
    Divider,
    Container,
    Accordion,
    Button,
    Timer,
    Header,
    Product,
    UpsellDeal,
    NextButton,
  },
  root: {
    fields: {
      fontFamily: {
        label: 'Font Family',
        type: 'select',
        options: fontFamilies,
      },
      themeColor: {
        type: 'custom',
        label: 'Theme Color',
        render: ({ value, onChange }) => {
          const handleChange = async (color: RGBColor) => {
            onChange(color);
            const iframe = document.getElementById(
              'preview-frame',
            ) as HTMLIFrameElement;

            if (iframe) {
              const iframeDocument = iframe.contentDocument as Document;
              const iframeBody = iframeDocument.body;

              if (iframeBody) {
                iframeBody.style.setProperty(
                  '--color-page-builder-theme',
                  `${color?.r} ${color?.g} ${color?.b}`,
                );
              }
            }
          };
          return (
            <FieldLabel label="Theme Color">
              <SketchPicker
                color={value || '#000'}
                onChange={(color: { rgb: RGBColor }) =>
                  handleChange(color?.rgb)
                }
              />
            </FieldLabel>
          );
        },
      },
      errorColor: {
        type: 'custom',
        label: 'Error Color',
        render: ({ value, onChange }) => {
          return (
            <FieldLabel label="Error Color">
              <SketchPicker
                color={value}
                onChange={(color: { hex: string }) => onChange(color?.hex)}
              />
            </FieldLabel>
          );
        },
      },
      customCss: {
        type: 'custom',
        label: 'Custom CSS',
        render: ({ value, onChange }) => {
          value = value || [];
          const [link, setLink] = useState<string>('');
          const [manualCss, setManualCss] = useState<string>(
            value.find((css: CssData) => css.name === 'Manual CSS')?.link ?? '',
          );
          const [errorMessage, setErrorMessage] = useState<boolean>(false);
          const handleAddCss = (type?: string): void => {
            const newCss: CssData = {
              id: uuidv4(),
              name: '',
              link: '',
              added: true,
            };
            if (type === 'external_css') {
              const regExp = urlRegExp;
              if (link && !!regExp.test(link)) {
                setErrorMessage(false);
              } else {
                setErrorMessage(true);
                return;
              }
              newCss.link = link;
              value.push(newCss);
              onChange([...value]);
              setLink('');
            } else {
              const index = value.findIndex(
                (css: CssData) => css.name === 'Manual CSS',
              );
              if (index !== -1) {
                value[index].link = manualCss;
                value[index].added = true;
                onChange([...value]);
              } else {
                newCss.name = 'Manual CSS';
                newCss.codeEditor = true;
                newCss.link = manualCss;
                value.push(newCss);
                onChange([...value]);
              }
            }
          };
          const handleEditCss = (newValue: string): void => {
            setLink(newValue);
          };

          const handleRemoveCss = (index: number): void => {
            const cssData = value[index];
            const RemoveCss = document.getElementById(
              `custom-css-${cssData.id}`,
            );
            if (RemoveCss) {
              RemoveCss.remove();
            }
            onChange(value.filter((_: unknown, i: number) => i !== index));
          };

          const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
          const handleEditorChange = (value: string | undefined) => {
            value && setManualCss(value);
          };

          return (
            <div>
              <div className="flex justify-between items-center">
                <label className="flex justify-between text-sm font-medium text-gray-700 pagebuilder-theme">
                  Custom CSS
                </label>
                <CommonButton
                  label="Open Code Editor"
                  className="!p-2 !text-[10px]"
                  variant="secondary"
                  disabled={manualCss === '' ? false : true}
                  onClick={() => setPopupOpen(!isPopupOpen)}
                />
              </div>
              {value.map(
                (css: CssData, index: number) =>
                  css.name && (
                    <div
                      key={index}
                      className="flex p-2 pt-3 justify-between items-center">
                      <div className="block font-semibold gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {css.name}
                      </div>
                      <div className="flex gap-2">
                        <button
                          className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                          type="button"
                          onClick={() => {
                            setPopupOpen(true);
                          }}>
                          <EditIcon />
                        </button>
                        <button
                          className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                          type="button"
                          onClick={() => {
                            handleRemoveCss(index);
                            setManualCss('');
                          }}>
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  ),
              )}

              {value.map(
                (css: CssData, index: number) =>
                  !css.name && (
                    <div key={css.id} className="flex gap-3 p-1">
                      <div className="block gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {css.link}
                      </div>
                      <button
                        className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                        type="button"
                        onClick={() => handleRemoveCss(index)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  ),
              )}
              <div className="flex flex-col">
                <input
                  className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in"
                  placeholder="Place your external css link here"
                  value={link}
                  onChange={e => handleEditCss(e.target.value)}
                />
                {errorMessage && (
                  <p className="text-red-500 text-xs">Invalid External URL</p>
                )}
                <CommonButton
                  label="+ Add"
                  className="mt-4 w-full"
                  variant="secondary"
                  onClick={() => handleAddCss('external_css')}
                />
              </div>
              <MonacoEditor
                isPopupOpen={isPopupOpen}
                setPopupOpen={setPopupOpen}
                value={manualCss}
                language={'scss'}
                handleEditorChange={handleEditorChange}
                handleSubmit={() => {
                  handleAddCss('manual_css');
                }}
              />
            </div>
          );
        },
      },
      customScripts: {
        type: 'custom',
        label: 'Custom Scripts',
        render: ({ value, onChange }) => {
          value = value || [];
          const [link, setLink] = useState<string>('');
          const [manualScript, setManualScript] = useState<string>(
            value.find((script: ScriptData) => script.name === 'Manual Script')
              ?.link ?? '',
          );
          const [errorMessage, setErrorMessage] = useState<boolean>(false);
          const handleAddScript = (type?: string): void => {
            const newScript: ScriptData = {
              id: uuidv4(),
              name: '',
              link: '',
              added: true,
            };
            if (type === 'external_script') {
              const regExp = urlRegExp;
              if (link && !!regExp.test(link)) {
                setErrorMessage(false);
              } else {
                setErrorMessage(true);
                return;
              }
              newScript.link = link;
              value.push(newScript);
              onChange([...value]);
              setLink('');
            } else {
              const index = value.findIndex(
                (script: ScriptData) => script.name === 'Manual Script',
              );
              if (index !== -1) {
                value[index].link = manualScript;
                value[index].added = true;
                onChange([...value]);
              } else {
                newScript.name = 'Manual Script';
                newScript.codeEditor = true;
                newScript.link = manualScript;
                value.push(newScript);
                onChange([...value]);
              }
            }
          };
          const handleEditScript = (newValue: string): void => {
            setLink(newValue);
          };

          const handleRemoveScript = (index: number): void => {
            const scriptData = value[index];
            const RemoveScript = document.getElementById(
              `custom-script-${scriptData.id}`,
            );
            if (RemoveScript) {
              RemoveScript.remove();
            }
            onChange(value.filter((_: unknown, i: number) => i !== index));
          };

          const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
          const handleEditorChange = (value: string | undefined) => {
            value && setManualScript(value);
          };

          return (
            <div>
              <div className="flex justify-between items-center">
                <label className="flex justify-between text-sm font-medium text-gray-700 pagebuilder-theme">
                  Custom Scripts
                </label>
                <CommonButton
                  label="Open Code Editor"
                  className="!p-2 !text-[10px]"
                  variant="secondary"
                  disabled={manualScript === '' ? false : true}
                  onClick={() => setPopupOpen(!isPopupOpen)}
                />
              </div>
              {value.map(
                (script: ScriptData, index: number) =>
                  script.name && (
                    <div
                      key={index}
                      className="flex p-1 pb-3 pt-3 justify-between items-center">
                      <div className="block font-semibold gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {script.name}
                      </div>
                      <div className="flex gap-2">
                        <button
                          className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                          type="button"
                          onClick={() => {
                            setPopupOpen(true);
                          }}>
                          <EditIcon />
                        </button>
                        <button
                          className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                          type="button"
                          onClick={() => {
                            handleRemoveScript(index);
                            setManualScript('');
                          }}>
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  ),
              )}

              {value.map(
                (script: ScriptData, index: number) =>
                  !script.name && (
                    <div key={script.id} className="flex gap-3 p-1">
                      <div className="block gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {script.link}
                      </div>
                      <button
                        className="ml-auto bg-red-600 hover:bg-blue-700 active:bg-blue-800 text-white border border-gray-300 rounded text-sm transition-colors duration-50 ease-in"
                        type="button"
                        onClick={() => handleRemoveScript(index)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  ),
              )}
              <div className="flex flex-col">
                <input
                  className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in"
                  placeholder="Place your external script link here"
                  value={link}
                  onChange={e => handleEditScript(e.target.value)}
                />
                {errorMessage && (
                  <p className="text-red-500 text-xs">Invalid External URL</p>
                )}
                <CommonButton
                  label="+ Add"
                  className="mt-4 w-full"
                  variant="secondary"
                  onClick={() => handleAddScript('external_script')}
                />
              </div>
              <MonacoEditor
                isPopupOpen={isPopupOpen}
                setPopupOpen={setPopupOpen}
                value={manualScript}
                language={'javascript'}
                handleEditorChange={handleEditorChange}
                handleSubmit={() => {
                  handleAddScript('manual_script');
                }}
              />
            </div>
          );
        },
      },
    },
    defaultProps: {
      fontFamily: fontFamilies[0].value,
      themeColor: { r: 79, g: 70, b: 229 },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
    render: ({
      children,
      fontFamily,
      errorColor,
      customCss,
      customScripts,
    }) => {
      useEffect(() => {
        if (customCss) {
          customCss.map((css: CssData) => {
            if (css.name) {
              if (document.getElementById(`custom-css-${css.id}`)) {
                document.getElementById(`custom-css-${css.id}`)?.remove();
              }
              const cssElement = document.createElement('style');
              cssElement.id = `custom-css-${css.id}`;
              cssElement.textContent = `.puck-page{
              ${css.link}
            }`;
              document.head.appendChild(cssElement);
            }
          });
        }
      }, [customCss]);

      useEffect(() => {
        if (customScripts) {
          customScripts.map((script: ScriptData) => {
            if (script.name === 'Manual Script') {
              document.getElementById(`custom-script-${script.id}`)?.remove();
            }
            if (
              script.added &&
              !document.getElementById(`custom-script-${script.id}`)
            ) {
              const scriptElement = document.createElement('script');
              scriptElement.id = `custom-script-${script.id}`;
              !script?.codeEditor
                ? (scriptElement.src = script.link)
                : (scriptElement.textContent = script.link);
              document.body.appendChild(scriptElement);
            }
          });
        }
      }, [customScripts]);

      return (
        <div
          className={`puck-page ${fontFamily ?? fontFamilies[0].value}`}
          style={
            {
              height: 'calc(100vh - 0px)',
              overflow: 'auto',
              '--error-color': errorColor ?? '#FF5A50',
            } as React.CSSProperties
          }>
          {customCss &&
            customCss.map(
              (css: CssData, index: number) =>
                !css.name && (
                  <link
                    key={index}
                    rel="stylesheet"
                    type="text/css"
                    href={css.link}></link>
                ),
            )}
          {children}
        </div>
      );
    },
  },
};

export default PostUpsellConfig;
