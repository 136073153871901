import { createSlice } from '@reduxjs/toolkit';
import { IStore } from '../../page-builder/interface/storeInterface';

export interface IStoreIDConfig {
  storeIds: IStore[];
  storeFronts: { ID: string; Name: string; ShopID: string };
}

const initialState: IStoreIDConfig = {
  storeIds: [],
  storeFronts: { ID: '', Name: '', ShopID: '' },
};

export const storeSlice = createSlice({
  name: 'storesConfig',
  initialState,
  reducers: {
    setStore: (state, action) => {
      return { ...state, storeIds: action.payload };
    },
    setStoreFronts: (state, action) => {
      return { ...state, storeFronts: action.payload };
    },
    clearStoreSlice: () => initialState,
  },
});

export const { setStore, setStoreFronts, clearStoreSlice } = storeSlice.actions;

export default storeSlice.reducer;
