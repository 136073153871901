import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CommonState {
  isTermsAccepted: boolean;
  submitSuccess: boolean;
  checkoutErrorMessage: string | null;
  purchaseCompleted: boolean;
}

const initialState: CommonState = {
  isTermsAccepted: false,
  submitSuccess: false,
  checkoutErrorMessage: null,
  purchaseCompleted: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsTermsAccepted(state, action: PayloadAction<boolean>) {
      state.isTermsAccepted = action.payload;
    },
    setSubmitSuccess(state, action: PayloadAction<boolean>) {
      state.submitSuccess = action.payload;
    },
    setCheckoutErrorMessage(state, action: PayloadAction<string | null>) {
      state.checkoutErrorMessage = action.payload;
    },
    setPurchaseCompleted(state, action: PayloadAction<boolean>) {
      state.purchaseCompleted = action.payload;
    },
  },
});

export const {
  setIsTermsAccepted,
  setSubmitSuccess,
  setCheckoutErrorMessage,
  setPurchaseCompleted,
} = commonSlice.actions;

export default commonSlice.reducer;
