import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import storefrontService from '../../page-builder/api/storefrontService';
import checkoutService from '../../page-builder/api/checkoutService';
import { ShippingMethod } from '../../page-builder/interface/checkoutInterface';
import {
  StartCheckoutPayload,
  GetPreCheckoutUpSellPayload,
} from '../interface/apiServiceInterface';
import { useDispatch } from 'react-redux';
import { setPreUpsellData } from '../../page-builder/redux/slices/preUpsellSlice';
import { setStoreData } from '../../page-builder/redux/slices/storeSlice';
import { useAppSelector } from './reduxHooks';
import { setPostUpsellData } from '../../page-builder/redux/slices/postUpsellSlice';
import { setAvailableShippingMethods } from '../../page-builder/redux/slices/checkoutSlice';
import { setCartData } from '../../page-builder/redux/slices/cartSlice';

export const usePreload = () => {
  // TODO: add sentry and uncomment the sentry related lines
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const shopId = searchParams.get('store')?.split('.')?.[0];
  const cartId = searchParams.get('cart');
  const trafficSource = searchParams.get('trafficSource');

  const [checkoutStarted, setCheckoutStarted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(0);
  const [error, setError] = useState<string | null>(null);
  const storeData = useAppSelector(state => state.store.storeData);
  const cartData = useAppSelector(state => state.cart.cartData);

  function checkoutErrHelperFunc(usrMsg: string, sysMsg?: string) {
    setError(usrMsg);
    if (!sysMsg) {
      sysMsg = usrMsg;
    }
    // Sentry.captureException(new Error(sysMsg));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePreloadError = useCallback((error: any) => {
    if (error?.status === 400 || error?.status === 404) {
      checkoutErrHelperFunc(
        'There was a problem with your request. Please check your information and try again.',
        `Failed to fetch cart details. Status: ${error?.status}`,
      );
    } else {
      checkoutErrHelperFunc(
        'Something went wrong! Try refreshing the page or try again later!',
        `Failed to fetch cart details. Status: ${error?.status}`,
      );
    }
  }, []);

  const getStoreData = useCallback(async () => {
    if (!shopId) {
      setError(
        'Something went wrong! Try refreshing the page or try again later!',
      );
      //   Sentry.captureException(
      //     new Error('Failed to fetch store details. Status: Invalid store id')
      //   );
      return;
    }
    setIsLoading(pre => pre + 1);
    try {
      const res = await storefrontService.getStoreDetails(shopId);
      dispatch(setStoreData(res.data));
    } catch (error: unknown) {
      handlePreloadError(error);
    } finally {
      setIsLoading(pre => pre - 1);
    }
  }, [shopId, dispatch, handlePreloadError]);

  useEffect(() => {
    getStoreData();
  }, [getStoreData]);

  const startCheckout = useCallback(async () => {
    if (!shopId || !cartId) {
      checkoutErrHelperFunc(
        'Something went wrong! Try refreshing the page or try again later!',
        'Failed to fetch cart details. Status: Invalid store or cart id',
      );
      return;
    }

    const input: StartCheckoutPayload = {
      CartID: cartId,
      ShopID: shopId,
      TrafficSource: trafficSource || '',
    };
    try {
      setIsLoading(pre => pre + 1);
      const res = await checkoutService.checkoutStart(input);
      dispatch(setCartData(res.data.Cart));
      dispatch(
        setAvailableShippingMethods(
          res?.data?.Cart?.AvailableShippingMethods as ShippingMethod[],
        ),
      );
    } catch (error: unknown) {
      handlePreloadError(error);
    } finally {
      setIsLoading(pre => pre - 1);
    }
  }, [shopId, cartId, trafficSource, dispatch, handlePreloadError]);

  useEffect(() => {
    if (storeData && shopId && cartId && !checkoutStarted) {
      startCheckout();
      setCheckoutStarted(true);
    }
  }, [startCheckout, storeData, shopId, cartId, checkoutStarted]);

  const getPreCheckoutUpSells = useCallback(async () => {
    if (!shopId || !cartId) {
      return;
    }

    const payload: GetPreCheckoutUpSellPayload = {
      Limit: 5,
      CartID: cartId,
      ShopID: shopId,
    };
    try {
      const res = await checkoutService.getPreCheckoutUpSells(payload);
      dispatch(setPreUpsellData(res.data.Result));
    } catch (error: unknown) {
      //   Sentry.captureException(new Error(`${error.message}: ${error.status}`));
    }
  }, [shopId, cartId, dispatch]);

  useEffect(() => {
    getPreCheckoutUpSells();
  }, [getPreCheckoutUpSells, shopId]);

  const getPostUpSells = useCallback(async () => {
    if (
      storeData &&
      cartData &&
      cartData?.LineItems &&
      cartData.LineItems.length > 0
    ) {
      const productIds = cartData.LineItems.map(
        (cartLine: { ProductID: string }) => cartLine.ProductID,
      );

      const payload = {
        Limit: 10,
        ProductIDs: productIds,
        ShopID: storeData?.ShopID,
      };

      try {
        const res = await checkoutService.getPostCheckoutUpSells(payload);
        dispatch(setPostUpsellData(res.data.Result));
      } catch (error: unknown) {
        // Sentry.captureException(new Error(`${error.message}: ${error.status}`));
      }
    }
  }, [cartData, dispatch, storeData]);

  useEffect(() => {
    getPostUpSells();
  }, [getPostUpSells]);

  return {
    isLoading: !!isLoading,
    error,
  };
};
