import { useEffect, useState } from 'react';
import { GuaranteeData } from './customerGuarantees';

export const Guarantee = ({
  id,
  img_src,
  name,
  description,
}: GuaranteeData) => {
  const [imgPath, setImgPath] = useState<string>('');
  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageModule = img_src
          ? await import(`../../../assets/icons/${img_src}.svg`)
          : '';
        setImgPath(imageModule.default);
      } catch (err) {
        setImgPath('');
      }
    };

    loadImage();
  }, [img_src]);
  return (
    <div key={id} className="flex items-center mt-10">
      <div className="border-2 border-white rounded-full p-2 bg-white">
        <img className="min-h-12 min-w-12" src={imgPath} alt="" />
      </div>
      <div className="ml-2">
        <div className="flex">
          <div className="flex items-center">
            <p className="font-bold text-sm">{name}</p>
          </div>
        </div>
        <dt className="text-gray-600 text-sm">{description}</dt>
      </div>
    </div>
  );
};
