import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { useAppSelector } from '../../hooks/reduxHooks';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { imageField } from '../../../page-builder/puck/reusable-props/image';
import {
  ImageProps,
  imageProps,
} from '../../../page-builder/puck/reusable-props/image-props';
import { CartIcon } from '../../../assets/icons/cartIcon';
import {
  fontField,
  FontProps,
} from '../../../page-builder/puck/reusable-props/font';
import {
  colorField,
  ColorProps,
} from '../../../page-builder/puck/reusable-props/color';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';

export type SimplePageHeaderProps = {
  imageFile: IAsset;
  backgroundImageFile: IAsset;
  absolute: boolean;
  logoAlign: boolean;
  showCart: boolean;
} & MarginProps &
  ImageProps &
  FontProps &
  ColorProps;

export const SimplePageHeader: ComponentConfig<SimplePageHeaderProps> = {
  label: 'Simple Page Header',
  fields: {
    imageFile: { ...imageField(true).file },
    backgroundImageFile: { ...imageField(false).file },
    logoAlign: {
      type: 'radio',
      label: 'Logo Align',
      options: [
        { label: 'Left', value: true },
        { label: 'Center', value: false },
      ],
    },
    absolute: {
      type: 'radio',
      label: 'Absolute',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
    },
    ...marginFields,
    ...imageProps([]),
    ...fontField,
    ...colorField,
    showCart: {
      type: 'radio',
      label: 'Show Cart',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
    },
  },
  defaultProps: {
    backgroundImageFile: {
      Version: 0,
      StoreID: '',
      Filename: 'background',
      BlobURL: '',
    },
    imageFile: {
      Version: 0,
      StoreID: '',
      Filename: 'logo',
      BlobURL: '',
    },
    margin: {
      desktop: {
        top: 'mt-4',
        bottom: 'mb-4',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-4',
        bottom: 'max-md:mb-4',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-2',
        bottom: 'max-sm:mb-2',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    image: {
      imageType: 'inline',
      backgroundPosition: 'bg-center',
      backgroundRepeat: 'bg-no-repeat',
      backgroundSize: 'bg-cover',
      borderRadius: 'rounded-md',
      border: 'border-none',
      maxWidth: 'w-auto',
      height: 'auto',
    },

    color: {
      backgroundColor: 'bg-transparent',
      hoverColor: 'hover:text-white',
      textColor: 'text-white',
    },
    font: {
      size: 'text-2xl',
      weight: 'font-bold',
    },
    absolute: false,
    logoAlign: true,
    showCart: false,
  },
  render: ({
    imageFile,
    backgroundImageFile,
    margin,
    image,
    font,
    color,
    logoAlign,
    absolute,
    showCart,
  }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const storeData = useAppSelector(state => state.store.storeData);
    const handleClick = () => {
      if (storeData?.ShopURL) {
        window.location.href = storeData?.ShopURL;
      }
    };

    return (
      <>
        <div
          className={`flex px-8 md:px-28 lg:px-24 xl:px-40 2xl:px-80 justify-between items-center w-full standardLayoutPadding
            max-[760px]:!bg-none ${absolute ? 'absolute' : ''} md:relative top-0 left-0 right-0 z-10
            ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}
             ${color?.backgroundColor}
             ${font?.size} ${font?.weight}
             ${image?.backgroundPosition}
            ${image?.backgroundRepeat}
            ${image?.backgroundSize}
            ${image?.border}
            ${image?.maxWidth}
            ${image?.borderRadius}
            ${image?.height}
          `}
          style={{
            backgroundImage: `url(${backgroundImageFile?.BlobURL})`,
          }}>
          {logoAlign === false && <div></div>}
          <div className="flex cursor-pointer" onClick={handleClick}>
            {imageFile?.BlobURL ? (
              <img
                className="w-[120px]"
                src={imageFile?.BlobURL}
                alt={imageFile?.Filename}
              />
            ) : queryParams.get('editMode') === 'true' ? (
              <button
                type="button"
                className="flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-page_builder_theme">
                Upload Logo
                <PlusIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            ) : (
              <button
                type="button"
                className="flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                Logo
              </button>
            )}
          </div>

          <div className="flex items-center cursor-pointer  h-[26px]">
            {showCart ? (
              <div onClick={handleClick}>
                <CartIcon />
              </div>
            ) : (
              <dd className="text-sm font-bold block cursor-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="green"
                  className="h-3 w-3 inline-block align-middle mb-1 mr-1">
                  <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                </svg>
                SECURE CONNECTION
              </dd>
            )}
          </div>
        </div>
      </>
    );
  },
};
