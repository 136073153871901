import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { useAppSelector } from '../../hooks/reduxHooks';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { imageField } from '../../../page-builder/puck/reusable-props/image';
import {
  ImageProps,
  imageProps,
} from '../../../page-builder/puck/reusable-props/image-props';
import {
  fontField,
  FontProps,
} from '../../../page-builder/puck/reusable-props/font';
import {
  colorField,
  ColorProps,
} from '../../../page-builder/puck/reusable-props/color';
import { paddingFields, PaddingProps } from '../../puck/reusable-props/padding';

export type CustomHeaderProps = {
  imageFile: IAsset;
} & MarginProps &
  ImageProps &
  FontProps &
  ColorProps &
  PaddingProps;

export const CustomHeader: ComponentConfig<CustomHeaderProps> = {
  label: 'Custom Header',
  fields: {
    imageFile: { ...imageField(true).file },
    ...marginFields,
    ...paddingFields,
    ...imageProps(['imageType', 'height']),
    ...fontField,
    ...colorField,
  },
  defaultProps: {
    imageFile: {
      Version: 0,
      StoreID: '',
      Filename: 'logo',
      BlobURL: 'https://picsum.photos/id/10/2500/1667',
    },
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    image: {
      imageType: 'inline',
      backgroundPosition: 'bg-center',
      backgroundRepeat: 'bg-no-repeat',
      backgroundSize: 'bg-cover',
      borderRadius: 'rounded-md',
      border: 'border-none',
      maxWidth: 'w-auto',
      height: 'h-[100px]',
    },

    font: {
      size: 'text-2xl',
      weight: 'font-bold',
    },
    color: {
      backgroundColor: 'bg-white',
      textColor: 'text-gray-900',
      hoverColor: 'hover:bg-gray-100',
    },
  },
  render: ({ imageFile, margin, image, color, padding }) => {
    const storeData = useAppSelector(state => state.store.storeData);
    const handleClick = () => {
      if (storeData?.ShopURL) {
        window.location.href = storeData?.ShopURL;
      }
    };
    return (
      <>
        <div
          className={`grid w-full grid-cols-[max-content_max-content] items-center justify-between
            max-[760px]:!bg-none  md:relative top-0 left-0 right-0 z-10 
            ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}
            ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}
            ${color?.backgroundColor}
            ${image?.backgroundPosition}
            ${image?.backgroundRepeat}
            ${image?.backgroundSize}
            ${image?.border}
            ${image?.maxWidth}
            ${image?.borderRadius}
          `}>
          <div
            className="flex cursor-pointer w-[100px] h-[50px] pl-2"
            onClick={handleClick}>
            {imageFile?.BlobURL ? (
              <img src={imageFile?.BlobURL} alt={imageFile?.Filename} />
            ) : (
              <p className="flex items-center bg-slate-500  text-wrap font-12 font-semibold">
                {' '}
                Upload logo
              </p>
            )}
          </div>
          <div className="flex items-center">
            <dd className="text-md font-bold block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="green"
                className="h-3 w-3 inline-block align-middle mb-1 mr-1">
                <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
              </svg>
              SECURE CONNECTION
            </dd>
          </div>
        </div>
      </>
    );
  },
};
