import { FieldLabel, Fields } from '@measured/puck';
import { ImageDrawer } from '../../../page-builder/components/image-uploader/image-drawer';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { useState } from 'react';
import { CloseIcon } from '../../../assets/icons/close-icon';

export type ImageProps = {
  file: IAsset;
};

export const defaultImage = {
  Filename: '',
  BlobURL: '',
  StoreID: '',
  ID: '',
  Version: 0,
};

export const imageField = (isBackground: boolean): Fields<ImageProps> => {
  return {
    file: {
      type: 'custom',
      render: ({ onChange }) => {
        const [selectedImage, setSelectedImage] =
          useState<IAsset>(defaultImage);
        const handleSubmit = (image: IAsset) => {
          setSelectedImage(image);
          onChange(image);
        };

        return (
          <FieldLabel label={`${isBackground ? 'Image' : 'Background Image'}`}>
            {selectedImage.BlobURL && (
              <div className="p-2 flex justify-between align-center border border-dashed rounded-md border-gray-400 mb-2 shadow-xl ">
                <span>{selectedImage?.Filename}</span>
                <span
                  className="cursor-pointer w-[15px] h-[15px]"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleSubmit(defaultImage);
                  }}>
                  <CloseIcon />
                </span>
              </div>
            )}
            <div className="flex justify-center p-[40px] border border-dashed rounded-md border-gray-400">
              <ImageDrawer
                handleSubmit={(selectedImage: IAsset) => {
                  handleSubmit(selectedImage);
                }}
                image={selectedImage}
              />
            </div>
          </FieldLabel>
        );
      },
    },
  };
};
