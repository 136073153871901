import axios, { AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

const retryStatusCodes = [409, 404, 408, 425, 429, 502, 503, 504];

const baseURL = process.env.REACT_APP_PHOENIX_STOREFRONT_URL;

const setInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async config => {
      if (!config.timeout) {
        config.timeout = 10000;
      }
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );
};

export const baseService = axios.create({
  baseURL,
});

setInterceptors(baseService);

axiosRetry(baseService, {
  retries: 2,
  retryCondition: (error: AxiosError) =>
    retryStatusCodes.includes(error?.response?.status as number),
  retryDelay: retryCount => retryCount * 100,
});
