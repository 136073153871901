import { createSlice } from '@reduxjs/toolkit';
import {
  IOrderDetails,
  IOrderTransactionHistory,
} from '../../page-builder/interface/orderDetailInterface';

export interface IOrderSlice {
  orderDetail: IOrderDetails | null;
  orderTransactions: IOrderTransactionHistory[];
}
const initialState: IOrderSlice = {
  orderDetail: null,
  orderTransactions: [],
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderDetail: (state, action) => {
      return { ...state, orderDetail: action.payload };
    },
    setOrderTransactions: (state, action) => {
      return { ...state, orderTransactions: action.payload };
    },
  },
});

export const { setOrderDetail, setOrderTransactions } = orderSlice.actions;

export default orderSlice.reducer;
