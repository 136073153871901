import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../../page-builder/puck/reusable-props/margin';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux-setup/store';
import {
  JustifyContentField,
  JustifyContentProps,
} from '../../../../page-builder/puck/reusable-props/justify-content';
import {
  fontField,
  FontProps,
} from '../../../../page-builder/puck/reusable-props/font';
import {
  AlignItemsProps,
  alignItemsField,
} from '../../../../page-builder/puck/reusable-props/align-items';
import {
  paddingFields,
  PaddingProps,
} from '../../../../page-builder/puck/reusable-props/padding';

export type HeaderProps = {
  Description: string;
} & MarginProps &
  PaddingProps &
  JustifyContentProps &
  FontProps &
  AlignItemsProps;

export const Header: ComponentConfig<HeaderProps> = {
  fields: {
    Description: { type: 'text', label: 'Description' },
    ...marginFields,
    ...paddingFields,
    ...JustifyContentField,
    ...fontField,
    ...alignItemsField,
  },
  defaultProps: {
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    justifyContent: 'justify-center',
    Description: "WAIT! YOUR ORDER ISN'T COMPLETE",
    font: {
      size: 'text-2xl',
      weight: 'font-bold',
    },
    alignItems: 'items-center',
  },
  render: ({
    margin,
    padding,
    justifyContent,
    Description,
    font,
    alignItems,
  }) => {
    const postUpsell = useSelector((state: RootState) => state.postUpSell);
    const postUpSellRecommendation = postUpsell.postUpsellData;
    const postUpsellState = useSelector(
      (state: RootState) => state.postUpSellState,
    );

    const currentRecommendation =
      postUpSellRecommendation[postUpsellState.currentIndex];

    return (
      <div
        className={`flex flex-col ${justifyContent} ${alignItems} ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}s`}>
        <div className={` ${font?.size} ${font?.weight}  text-center`}>
          <h1>{Description}</h1>
          <h2>
            SAVE{' '}
            {(
              Number(postUpsellState.selectedVariant.OriginalPrice) -
              Number(postUpsellState.selectedVariant.Price)
            ).toFixed(2)}{' '}
            OFF OUR {currentRecommendation.Title}
          </h2>
        </div>
      </div>
    );
  },
};
