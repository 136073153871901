import { ComponentConfig, DropZone } from '@measured/puck';
import { flexDirections } from '../../../../page-builder/puck/reusable-props/options';
import './styles.scss';
import {
  GapProps,
  gapField,
} from '../../../../page-builder/puck/reusable-props/gap';
import {
  paddingFields,
  PaddingProps,
} from '../../../../page-builder/puck/reusable-props/padding';
import {
  marginFields,
  MarginProps,
} from '../../../../page-builder/puck/reusable-props/margin';

export type FlexProps = {
  items: { minItemSize?: number }[];
  minItemSize: number;
  flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse';
} & PaddingProps &
  GapProps &
  MarginProps;
export const Flex: ComponentConfig<FlexProps> = {
  fields: {
    flexDirection: {
      label: 'Direction',
      type: 'select',
      options: flexDirections,
    },
    items: {
      label: 'Items',
      type: 'array',
      arrayFields: {
        minItemSize: {
          label: 'Minimum Item Width/Height',
          type: 'number',
          min: 0,
        },
      },
      getItemSummary: (_, id) => `Item ${Number(id) + 1}`,
    },
    ...gapField,
    ...paddingFields,
    ...marginFields,
    minItemSize: {
      label: 'Minimum Item Width/Height',
      type: 'number',
      min: 0,
    },
  },
  defaultProps: {
    items: [{}, {}],
    minItemSize: 0,
    flexDirection: 'row',
    gap: 'gap-2',
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({ items, minItemSize, flexDirection, padding, gap, margin }) => {
    return (
      <div
        className={`Flex ${gap} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right} ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}
        style={{
          flexDirection,
        }}>
        {items.map((item, idx) => (
          <div
            key={idx}
            className={'Flex-item'}
            style={
              flexDirection === 'row' || flexDirection === 'row-reverse'
                ? { minWidth: item.minItemSize || minItemSize }
                : { minHeight: item.minItemSize || minItemSize }
            }>
            <DropZone zone={`item-${idx}`} />
          </div>
        ))}
      </div>
    );
  },
};
