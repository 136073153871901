import { useEffect, useState } from 'react';
import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';

export type TimerProps = {
  backgroundColor: string;
  interval: number;
  saleText: string;
  saleTextColor: string;
  bodyText: string;
  bodyTextColor: string;
} & MarginProps;

export const Timer: ComponentConfig<TimerProps> = {
  fields: {
    backgroundColor: {
      type: 'text',
      label: 'Background Color (Hex)',
    },
    interval: { type: 'number', label: 'Interval' },
    saleText: { type: 'text', label: 'Sale Text' },
    saleTextColor: { type: 'text', label: 'Sale Text Color (Hex)' },
    bodyText: { type: 'text', label: 'Body Text' },
    bodyTextColor: { type: 'text', label: 'Body Text Color (Hex)' },
    ...marginFields,
  },
  defaultProps: {
    backgroundColor: '#ECFEFF',
    interval: 10 * 60,
    saleText: '50% OFF SALE ENDS TODAY!',
    saleTextColor: '#FF0000',
    bodyText:
      'Items in your cart are in high demand. No Worries, we reserved your order for',
    bodyTextColor: '#000000',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
  },
  render: ({
    backgroundColor,
    interval,
    saleText,
    saleTextColor,
    bodyText,
    bodyTextColor,
    margin,
  }) => {
    const [time, setTime] = useState(interval);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      return () => clearInterval(intervalId);
    }, []);

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return (
      <div
        style={{ backgroundColor }}
        className={`rounded flex py-1 ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
        <p className="bg-red-700 text-white rounded-full w-3 h-3 flex items-center justify-center text-xxs flex-shrink-0 mt-3 mx-2">
          %
        </p>
        <div>
          <b className={`text-lg font-bold ${saleTextColor}`}>{saleText} </b>
          <span className={`text-lg ${bodyTextColor}`}>{bodyText} </span>
          <span className="text-red-500 font-bold text-lg text-center">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        </div>
      </div>
    );
  },
};
