import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { v4 as uuidv4 } from 'uuid';
import { Guarantee } from './guarantee';
import Select from '../../../components/common/select';
import Button from '../../../components/common/button';
export type CustomerGuarnteesProps = {
  guarantees: GuaranteeData[];
  placeHolder: string;
} & MarginProps;

export interface GuaranteeData {
  id: string;
  img_src: string;
  name: string;
  description: string;
}
const dropdownValues = [
  { value: 'delivery', label: 'delivery' },
  { value: 'calendar', label: 'calendar' },
];
export const CustomerGuarantees: ComponentConfig<CustomerGuarnteesProps> = {
  label: 'Customer Guarantees',
  fields: {
    placeHolder: { type: 'text', label: 'Place Holder' },
    guarantees: {
      type: 'custom',
      label: 'Guarantee',
      render: ({ value, onChange }) => {
        value = value || [];

        const handleAddGuarantee = () => {
          const newGuarantee: GuaranteeData = {
            id: uuidv4(),
            img_src: '',
            name: '',
            description: '',
          };
          onChange([newGuarantee, ...value]);
        };

        const handleEditGuarantee = (
          index: number,
          key: keyof GuaranteeData,
          newValue: string,
        ) => {
          onChange(
            value.map((guarantee: GuaranteeData, i: number) =>
              i === index ? { ...guarantee, [key]: newValue } : guarantee,
            ),
          );
        };

        const handleRemoveGuarantee = (index: number) => {
          onChange(value.filter((_: unknown, i: number) => i !== index));
        };

        return (
          <div>
            <Button
              label="+ Add new Guarantee"
              className="mt-4 w-full"
              variant="phoenix"
              onClick={handleAddGuarantee}
            />
            {value.map((guarantee: GuaranteeData, index: number) => (
              <div key={guarantee.id}>
                <Select
                  onChange={e => {
                    handleEditGuarantee(index, 'img_src', e);
                  }}
                  value={guarantee.img_src}
                  label="Image"
                  options={dropdownValues}
                  placeholder="Image"
                  className="max-w-[160px]"
                />
                <input
                  className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in pagebuilder-theme"
                  placeholder="Name"
                  value={guarantee.name}
                  onChange={e =>
                    handleEditGuarantee(index, 'name', e.target.value)
                  }
                />
                <textarea
                  className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in pagebuilder-theme"
                  placeholder="Description"
                  value={guarantee.description}
                  onChange={e =>
                    handleEditGuarantee(index, 'description', e.target.value)
                  }
                />
                <Button
                  label="- Remove Guarantee"
                  className="mt-4 w-full"
                  variant="secondary"
                  onClick={() => handleRemoveGuarantee(index)}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    ...marginFields,
  },
  defaultProps: {
    placeHolder: 'Guarantees',
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    guarantees: [],
  },
  render: ({ placeHolder, margin, guarantees }) => {
    return (
      <div
        className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
        <div className="pb-5 border-b border-gray-200">
          <div className="flex items-center justify-center">
            <div className="flex-grow border-b border-black mx-2"></div>
            <h2
              id="contact-info-heading"
              className="text-bold text-lg font-medium text-gray-900 text-center">
              {placeHolder}
            </h2>
            <div className="flex-grow border-b border-black mx-2"></div>
          </div>
          {guarantees && guarantees.length === 0 && (
            <div className="mt-10 text-center text-lg text-gray-600">
              No Guarantees yet
            </div>
          )}

          {guarantees?.map(data => {
            return (
              <Guarantee
                key={data.id}
                id={data.id}
                img_src={data.img_src}
                name={data.name}
                description={data.description}
              />
            );
          })}
        </div>
      </div>
    );
  },
};
