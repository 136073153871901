import { FormData } from '../interface/checkoutInterface';

export const Path = {
  // Page Builder routes
  PAGE_BUILDER: '/page-builder/*',
  PAGE_BUILDER_ROUTE: '/page-builder',
};
export const getPagePath = (query: string) => {
  return `${Path?.PAGE_BUILDER_ROUTE}/?${query}`;
};
export const defaultValues: FormData = {
  ID: '',
  ShopID: '',
  HasSubscription: false,
  terms_of_service: false,
  Items: [],
  TotalAmount: '',
  CustomerData: {
    Email: '',
    PhoneNumber: '',
  },
  Shipping: {
    FirstName: '',
    LastName: '',
    Address1: '',
    Address2: '',
    City: '',
    CountryCode: '',
    ProvinceCode: '',
    Zip: '',
    Organization: '',
    PhoneNumber: '',
  },
  phone_code: '',
  billing_same_as_shipping: true,
  BillingAddress: {
    FirstName: '',
    LastName: '',
    Address1: '',
    Address2: '',
    City: '',
    CountryCode: '',
    ProvinceCode: '',
    Zip: '',
    Organization: '',
    PhoneNumber: '',
  },
  ShippingMethodID: '',
};
export const standardPostUpsellLayout = {
  content: [
    {
      type: 'Columns',
      props: {
        gap: 'gap-2',
        distribution: 'auto',
        columns: [{}],
        padding: {
          desktop: {
            top: 'pt-4',
            bottom: 'pb-4',
            right: 'pr-4',
            left: 'pl-4',
          },
          tablet: {
            top: 'max-md:pt-1',
            bottom: 'max-md:pb-1',
            right: 'max-md:pr-1',
            left: 'max-md:pl-1',
          },
          mobile: {
            top: 'max-sm:pt-1',
            bottom: 'max-sm:pb-1',
            right: 'max-sm:pr-0',
            left: 'max-sm:pl-0',
          },
        },
        direction: 'column',
        margin: {
          desktop: {
            top: 'mt-0',
            bottom: 'mb-0',
            right: 'mr-0',
            left: 'ml-0',
          },
          tablet: {
            top: 'max-md:mt-1',
            bottom: 'max-md:mb-1',
            right: 'max-md:mr-1',
            left: 'max-md:ml-1',
          },
          mobile: {
            top: 'max-sm:mt-1',
            bottom: 'max-sm:mb-1',
            right: 'max-sm:mr-0',
            left: 'max-sm:ml-1',
          },
        },
        id: 'Columns-fb52eccd-c886-40d2-a989-b17a7ebf216b',
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'sans-serif',
      themeColor: {
        r: 79,
        g: 70,
        b: 229,
      },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'Columns-fb52eccd-c886-40d2-a989-b17a7ebf216b:column-0': [
      {
        type: 'TextField',
        props: {
          header: 'Final offer sale!',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'h-10',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-b84f1bc4-ab6e-4204-af67-d5cad60eb490',
        },
      },
      {
        type: 'Header',
        props: {
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          Description: "WAIT! YOUR ORDER ISN'T COMPLETE",
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          id: 'Header-8646fc24-37d7-4e21-a018-4825e79bce99',
        },
      },
      {
        type: 'Columns',
        props: {
          gap: 'gap-2',
          distribution: 'auto',
          columns: [{}, {}],
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          direction: 'column',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Columns-8651dc36-753b-4c0e-95da-60da146c05a7',
        },
      },
      {
        type: 'NextButton',
        props: {
          NextButton: "NO, THANK YOU, I DON'T WANT THIS LIMITED TIME OFFER",
          nextPagePath: {
            label: '',
            value: '',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          id: 'NextButton-672c84b3-e56a-400c-99f5-7983ac54ecea',
        },
      },
    ],
    'Columns-8651dc36-753b-4c0e-95da-60da146c05a7:column-0': [
      {
        type: 'Timer',
        props: {
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          Description: 'RESERVED FOR THE NEXT:',
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          id: 'Timer-c63ba296-fdae-4f63-9e8c-0cf9a01b79c9',
        },
      },
      {
        type: 'UpsellDeal',
        props: {
          nextPagePath: {
            label: '',
            value: '',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          Description: "WAIT! YOUR ORDER ISN'T COMPLETE",
          Offer: 'SAVE 43.56 OFF OUR ',
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          width: 'w-full',
          id: 'UpsellDeal-9a678f1f-1bf2-4e1c-ae51-ca78179a0342',
        },
      },
    ],
    'Columns-8651dc36-753b-4c0e-95da-60da146c05a7:column-1': [
      {
        type: 'Product',
        props: {
          Product: 'Product',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          id: 'Product-7970cba2-dff4-4606-bbbf-c14ffae19783',
        },
      },
    ],
  },
};
export const standardCheckoutLayout = {
  content: [
    {
      type: 'StandardLayout',
      props: {
        border: false,
        borderColor: '#E5E5E5',
        bgColor: '#F9F9F9',
        orderSummaryCartTotal: false,
        orderSummaryTextColor: '#000',
        orderSummaryText: 'Show order summary',
        id: 'StandardLayout-daefc640-22a4-47f2-bf94-b039ecfeaa51',
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'inter',
      themeColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'StandardLayout-daefc640-22a4-47f2-bf94-b039ecfeaa51:top': [
      {
        type: 'SimplePageHeader',
        props: {
          backgroundImageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'background',
            BlobURL: '',
          },
          imageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'logo',
            BlobURL: '',
          },
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-4',
              bottom: 'max-md:mb-4',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-2',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          image: {
            imageType: 'inline',
            backgroundPosition: 'bg-center',
            backgroundRepeat: 'bg-no-repeat',
            backgroundSize: 'bg-cover',
            borderRadius: 'rounded-md',
            border: 'border-none',
            maxWidth: 'w-auto',
            height: 'h-auto',
          },
          color: {
            backgroundColor: 'bg-transparent',
            hoverColor: 'hover:text-white',
            textColor: 'text-white',
          },
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          absolute: false,
          logoAlign: true,
          showCart: false,
          id: 'SimplePageHeader-5c986a03-25bb-4b58-9514-83ba87c379c1',
        },
      },
    ],
    'StandardLayout-daefc640-22a4-47f2-bf94-b039ecfeaa51:left': [
      {
        type: 'TextField',
        props: {
          header: 'STEP 1: INTERESTED IN ANYTHING ELSE?',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-4',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-lg',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-cab07d9a-eb31-4933-a77e-8b7d52fec0d6',
        },
      },
      {
        type: 'UpSell',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-2',
              bottom: 'max-sm:mb-4',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'UpSell-3be49a6a-e80e-49ff-b112-02dc84f921c5',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 2: CONTACT INFORMATION',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-lg',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-880095a7-bfea-46e9-849a-de75efef633f',
        },
      },
      {
        type: 'ContactInfo',
        props: {
          placeholder: 'Email Address',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'ContactInfo-5164aeb9-64c2-44c2-a012-0a9fd4d4fcad',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 3: SHIPPING ADDRESS',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-lg',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-d78b1cb8-be8e-4cef-8343-2b58b8e6916e',
        },
      },
      {
        type: 'ShippingForm',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'ShippingForm-e261583a-8a36-4bb5-94db-96ee76252a59',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 4: BIILLING ADDRESS',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-lg',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-2d1b9018-1cbd-440f-9736-ae911496f5e1',
        },
      },
      {
        type: 'BillingForm',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'BillingForm-34f3f6c7-b6b6-4cc7-bcaf-6e5ef32be39f',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 5: SHIPPING METHODS',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-2',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-lg',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-7b4d83f3-2eed-426c-a8f9-8a8ffc34b9e4',
        },
      },
      {
        type: 'ShippingMethodSelect',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ShippingMethodSelect-892c8c22-7125-4a9c-97f1-54c5e9ebcf4e',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 6: PAYMENT METHODS',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-2',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-lg',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-39c38ab9-54f0-4bcc-b698-c0938598a989',
        },
      },
      {
        type: 'Payment',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Payment-53b020b6-55db-467e-9c88-e87065c4f939',
        },
      },
      {
        type: 'ConfirmPurchase',
        props: {
          nextPagePath: {
            label: '',
            value: '',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ConfirmPurchase-ec9b1e08-290e-4551-84f4-2ebbe336862a',
        },
      },
    ],
    'StandardLayout-daefc640-22a4-47f2-bf94-b039ecfeaa51:right': [
      {
        type: 'Cart',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Cart-7d5ff87f-047a-472b-b53a-286d831a119c',
        },
      },
      {
        type: 'PromoCode',
        props: {
          placeholder: 'Promo Code (Case Sensitive)',
          margin: {
            desktop: {
              top: 'mt-6',
              bottom: 'mb-6',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'PromoCode-7854f219-847c-4e27-ad9c-2790c59806f2',
        },
      },
      {
        type: 'OrderDetails',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'OrderDetails-085c5413-61e0-45eb-affc-7188fe643712',
        },
      },
      {
        type: 'CustomerReviews',
        props: {
          placeHolder: 'Customer Reviews',
          reviews: [
            {
              id: '378cd437-1022-4092-a7fc-be1f18bc4ee7',
              name: 'Kevin L',
              review:
                'I really like the Cross Skull Bead Bracelet! It’s weighty and the skull details add a cool, rugged look. It’s comfortable for all-day wear and perfect for enhancing a rugged style.',
            },
            {
              id: '0a08200e-bfed-4656-a5a9-0ec5465ca3b6',
              name: 'John T',
              review:
                "I love the Thor's Hammer Celtic Knot Viking Ring! It beautifully combines Viking lore with elegance. The craftsmanship is top-notch, making it lightweight yet durable for everyday wear. Highly recommend for its superb quality and style.",
            },
            {
              id: 'f2e179f2-3851-46d9-9ae6-d08dda25bac6',
              name: 'Aiden H',
              review:
                "I love the Thor's Hammer Celtic Knot Viking Ring! It beautifully combines Viking lore with elegance. The craftsmanship is top-notch, making it lightweight yet durable for everyday wear. Highly recommend for its superb quality and style.",
            },
          ],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'CustomerReviews-24a33b7b-8501-4378-8e2f-cb724df1a824',
        },
      },
      {
        type: 'CustomerGuarantees',
        props: {
          placeHolder: 'Why Us',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          guarantees: [
            {
              id: '7d888755-f257-4727-b690-be7833a6b55b',
              img_src: 'calendar',
              name: "If you're not satisfied with your product(s), we'll make it right. That's our promise!",
              description:
                "If you're not satisfied with your product(s), we'll make it right. That's our promise!",
            },
            {
              id: '4a44df8e-94b7-4250-8358-3dc84e5f0ede',
              img_src: 'delivery',
              name: 'Items in your cart are in high demand. No Worries, we reserved your order.',
              description:
                "We ship out quality products, it's just one of the reasons we have so many happy repeat customers!",
            },
          ],
          id: 'CustomerGuarantees-1249283e-2e7b-402a-9923-821c2f3aa17d',
        },
      },
      {
        type: 'VipComponent',
        props: {
          title: 'VIP Club',
          description:
            'By taking advantage of this offer, you will be signed up for our exclusive VIP Club program.  ',
          vipClubBenefitTitle:
            'The VIP Club is an exclusive program that provides you with',
          vipClubBenefits:
            'Access to VIP pricing on all website products.,\n    Access to hundreds of trending products through the VIP Portal.,\n    Lowest price guarantee on all VIP Portal orders.,\n    Priority 24/7 customer support.',
          content:
            '<p><span style="color: rgb(0, 0, 0);">You will be billed 29 dollars 99 cents monthly till cancellation. If you do not want to continue the VIP Club after the introductory period or if you wish to cancel the VIP Club at any time, you may do so by filling out the&nbsp;</span><a href="https://offer.enhanced-essence.com/pages/membership-cancellation" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 57, 201);">Cancel Membership</a><span style="color: rgb(0, 0, 0);">&nbsp;form on our Website, by emailing us or filling out the form on our website now at least 3 days before the next billing period with your full name and the email and physical address associated with your use of the Service.</span></p>',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-4',
              left: 'pl-4',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          font: {
            size: 'text-2xl',
            weight: 'font-normal',
          },
          alignItems: 'items-center',
          id: 'VipComponent-c7479be8-d902-41fa-8302-dcf93fd205af',
        },
      },
    ],
  },
};
export const initialThankYouData = {
  content: [
    {
      type: 'Columns',
      props: {
        gap: 'gap-0',
        distribution: 'manual',
        columns: [
          {
            span: 7,
          },
          {
            span: 5,
          },
        ],
        paddingLeft: 'pl-0',
        paddingRight: 'pr-0',
        paddingTop: 'pt-0',
        paddingBottom: 'pb-0',
        direction: 'column',
        id: 'Columns-1c0e2b4b-1528-45ea-9c38-755ac22bddf9',
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'sans-serif',
      themeColor: { r: 79, g: 70, b: 229 },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'Columns-1c0e2b4b-1528-45ea-9c38-755ac22bddf9:column-0': [
      {
        type: 'Container',
        props: {
          flexDirection: 'row',
          backgroundColor: '#FFFFFF',
          border: false,
          borderRadius: 'rounded-none',
          borderWidth: 'border-0',
          borderColor: '#000000',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-0',
          marginBottom: 'mb-0',
          paddingLeft: 'pl-8',
          paddingRight: 'pr-8',
          paddingTop: 'pt-0',
          paddingBottom: 'pb-0',
          gap: 'gap-2',
          id: 'Container-d5e1ffae-1112-47a5-9282-028e7f194690',
        },
      },
    ],
    'Columns-1c0e2b4b-1528-45ea-9c38-755ac22bddf9:column-1': [
      {
        type: 'Container',
        props: {
          flexDirection: 'row',
          backgroundColor: '#000000',
          border: false,
          borderRadius: 'rounded-none',
          borderWidth: 'border-0',
          borderColor: '#000000',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-0',
          marginBottom: 'mb-0',
          paddingLeft: 'pl-8',
          paddingRight: 'pr-8',
          paddingTop: 'pt-16',
          paddingBottom: 'pb-16',
          gap: 'gap-2',
          id: 'Container-d95291b8-afa0-47cb-80a7-9808b22a6a54',
        },
      },
    ],
    'Container-d5e1ffae-1112-47a5-9282-028e7f194690:Container': [
      {
        type: 'TextField',
        props: {
          header: 'Thank You For Your Purchase',
          marginLeft: 'ml-0',
          marginRight: 'mr-0',
          marginTop: 'mt-16',
          marginBottom: 'mb-4',
          fontSize: 'text-lg',
          color: '#000000',
          alignText: 'left',
          fontBold: true,
          id: 'TextField-8835d38c-9304-426c-ae5e-44eb38177d16',
        },
      },
      {
        type: 'TextEditor',
        props: {
          content:
            '<p>Your order with&nbsp;Sandbox 2024&nbsp;is completed. Your order will ship within the next 24-48 business hours.</p>',
          id: 'TextEditor-29bec050-1ab3-46aa-a9ef-fc48626c2797',
        },
      },
      {
        type: 'VerticalSpace',
        props: {
          size: '24px',
          id: 'VerticalSpace-9aa724be-afbd-49e8-bb52-85087bbec37e',
        },
      },
      {
        type: 'TextEditor',
        props: {
          content:
            '<p><span style="color: rgb(75, 85, 99);">If you have any questions or concerns please contact us at&nbsp;</span><a href="mailto:support@goons.com" rel="noopener noreferrer" target="_blank" style="color: rgb(75, 85, mailto:99);">support@goons.com</a><span style="color: rgb(75, 85, 99);">, or by calling us at&nbsp;800-555-7890.</span></p>',
          id: 'TextEditor-59fce204-a05d-45de-a0d6-548c75ee9baa',
        },
      },
      {
        type: 'VerticalSpace',
        props: {
          size: '24px',
          id: 'VerticalSpace-caa91b14-03e4-4785-8a54-b93f2570986f',
        },
      },
      {
        type: 'TextEditor',
        props: {
          content:
            '<p><span style="color: rgb(75, 85, 99);">Need help?&nbsp;</span><a href="https://google.com/" rel="noopener noreferrer" target="_blank" style="color: rgb(75, 85, 99);">Contact us</a></p>',
          id: 'TextEditor-2c3992aa-5248-46ab-a3fa-af2bfa9cce2f',
        },
      },
      {
        type: 'VerticalSpace',
        props: {
          size: '24px',
          id: 'VerticalSpace-156a5432-4d1e-486a-accb-0f971471fdce',
        },
      },
      {
        type: 'Divider',
        props: {
          size: '1px',
          direction: 'height',
          id: 'Divider-0bed1ec8-2c8d-4024-92b5-bd1ba2f27fd0',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'All rights reserved Sandbox 2024.',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-4',
          marginBottom: 'mb-0',
          fontSize: 'text-sm',
          color: '#000000',
          alignText: 'left',
          fontBold: false,
          id: 'TextField-39fdf978-dd64-4fa0-a6af-b814595bdf6d',
        },
      },
    ],
    'Container-d95291b8-afa0-47cb-80a7-9808b22a6a54:Container': [
      {
        type: 'TextField',
        props: {
          header: 'Email Address',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-0',
          marginBottom: 'mb-2',
          fontSize: 'text-xl',
          color: '#FFFFFF',
          alignText: 'left',
          fontBold: true,
          id: 'TextField-8775eaca-85fd-4504-af9d-272a2fe8dab6',
          textColor: '#ffffff',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'mailto:vignesh@softsuave.com',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-0',
          marginBottom: 'mb-2',
          fontSize: 'text-lg',
          color: '#FFF',
          alignText: 'left',
          fontBold: false,
          id: 'TextField-484b977d-5161-4934-8bb8-30e3d6a12dc1',
          textColor: '#ffffff',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'Shipping Address',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-0',
          marginBottom: 'mb-2',
          fontSize: 'text-xl',
          color: '#FFFFFF',
          alignText: 'left',
          fontBold: true,
          id: 'TextField-330d2040-6fc7-497c-9da0-99dc365334c0',
          textColor: '#fdfdfd',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'Test address line 1',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-0',
          marginBottom: 'mb-2',
          fontSize: 'text-lg',
          color: '#FFF',
          alignText: 'left',
          fontBold: false,
          id: 'TextField-d0a539b7-72cf-4601-8a0d-a89a1f3491a7',
          textColor: '#ffffff',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'Chennai Tami Nadu',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-0',
          marginBottom: 'mb-2',
          fontSize: 'text-lg',
          color: '#FFF',
          alignText: 'left',
          fontBold: false,
          id: 'TextField-833dd9e6-03e2-41ca-ac17-b9798902fa27',
          textColor: '#ffffff',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'India',
          marginLeft: 'mb-0',
          marginRight: 'mr-0',
          marginTop: 'mt-0',
          marginBottom: 'mb-2',
          fontSize: 'text-lg',
          color: '#FFF',
          alignText: 'left',
          fontBold: false,
          id: 'TextField-df17518d-b5ff-4f82-8d30-bde85a74234a',
          textColor: '#ffffff',
        },
      },
    ],
  },
};
export const initialFreeTemplateData = {
  content: [],
  root: {},
  zones: {},
};
export const urlRegExp =
  /^https?:\/\/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
export const emailRegExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
export const phoneRegExp = /^\(?\d{2,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;

export const navigationPathComponents = [
  'NextButton',
  'UpsellDeal',
  'ConfirmPurchase',
  'Button',
  'ButtonSection',
];

export const defaultComponentProps = {
  header: 'Header',
  //checkout
  contactInfo: 'ContactInfo',
  shippingForm: 'ShippingForm',
  shippingMethod: 'ShippingMethod',
  preUpsell: 'PreUpsell',
  billingForm: 'BillingForm',
  payment: 'Payment',
  confirmPurchase: 'ConfirmPurchase',
  cart: 'Cart',
  orderDetails: 'OrderDetails',
  customerReviews: 'CustomerReviews',
  customerGuarantees: 'CustomerGuarantees',
  promoCode: 'PromoCode',
  termsAndSubscription: 'TermsAndSubscription',
  //post-upsells
  dealConfirmButton: 'UpsellDeal',
  nextProductButton: 'NextButton',
  productVarient: 'ProductVarient',
  timer: 'Timer',
  vip: 'Vip',
};

export const mandatoryComponents: { [key: string]: string[] } = {
  checkout: [
    // defaultComponentProps.header,
    defaultComponentProps.contactInfo,
    defaultComponentProps.shippingForm,
    defaultComponentProps.billingForm,
    defaultComponentProps.payment,
    defaultComponentProps.confirmPurchase,
    defaultComponentProps.cart,
    defaultComponentProps.orderDetails,
    // defaultComponentProps.customerReviews,
    // defaultComponentProps.customerGuarantees,
  ],
  upsells: [
    defaultComponentProps.header,
    defaultComponentProps.timer,
    defaultComponentProps.productVarient,
    defaultComponentProps.dealConfirmButton,
    defaultComponentProps.nextProductButton,
  ],
  thankyou: ['TextField', 'TextEditor', 'VerticalSpace', 'Divider'],
};

export const simpleCheckoutLayout = {
  content: [
    {
      type: 'Container',
      props: {
        position: '',
        flexDirection: 'row',
        backgroundColor: 'none',
        border: false,
        borderRadius: 'rounded-none',
        borderWidth: 'border-0',
        borderColor: '#000000',
        margin: {
          desktop: {
            top: 'mt-4',
            bottom: 'mb-4',
            right: 'mr-32',
            left: 'ml-32',
          },
          tablet: {
            top: 'max-md:mt-1',
            bottom: 'max-md:mb-1',
            right: 'max-md:mr-1',
            left: 'max-md:ml-1',
          },
          mobile: {
            top: 'max-sm:mt-1',
            bottom: 'max-sm:mb-1',
            right: 'max-sm:mr-0',
            left: 'max-sm:ml-0',
          },
        },
        padding: {
          desktop: {
            top: 'pt-0',
            bottom: 'pb-0',
            right: 'pr-0',
            left: 'pl-0',
          },
          tablet: {
            top: 'max-md:pt-1',
            bottom: 'max-md:pb-1',
            right: 'max-md:pr-1',
            left: 'max-md:pl-1',
          },
          mobile: {
            top: 'max-sm:pt-1',
            bottom: 'max-sm:pb-1',
            right: 'max-sm:pr-1',
            left: 'max-sm:pl-1',
          },
        },
        gap: 'gap-2',
        id: 'Container-583d1d34-9cb6-4e35-b020-33b73dd3d174',
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'sans-serif',
      themeColor: {
        r: 79,
        g: 70,
        b: 229,
      },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'Container-583d1d34-9cb6-4e35-b020-33b73dd3d174:Container': [
      {
        type: 'Columns',
        props: {
          gap: 'gap-8',
          distribution: 'auto',
          columns: [{}, {}],
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          direction: 'column-reverse',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Columns-878910bf-dce4-4e1e-9abb-0749dc68164a',
        },
      },
    ],
    'Columns-878910bf-dce4-4e1e-9abb-0749dc68164a:column-0': [
      {
        type: 'HeaderCheckout',
        props: {
          backgroundImageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'background',
            BlobURL: 'https://picsum.photos/id/0/5000/3333',
          },
          imageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'logo',
            BlobURL: 'https://picsum.photos/id/10/2500/1667',
          },
          headerText: 'My Store',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          image: {
            imageType: 'inline',
            backgroundPosition: 'bg-center',
            backgroundRepeat: 'bg-no-repeat',
            backgroundSize: 'bg-cover',
            borderRadius: 'rounded-md',
            border: 'border-none',
            maxWidth: 'w-auto',
            height: 'h-[100px]',
          },
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          id: 'HeaderCheckout-957d909e-6fd4-42c7-a3b8-e5dd6ee192e6',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 1: INTERESTED IN ANYTHING ELSE?',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-1',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-af829bc2-0f41-4f82-8764-508d98311343',
        },
      },
      {
        type: 'UpSell',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'UpSell-54036195-9fde-4c4c-9314-acba1bd2ee36',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 2: CONTACT INFORMATION',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-b1d765b5-d48a-4d9d-9add-c202f0072224',
        },
      },
      {
        type: 'ContactInfo',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ContactInfo-ebb1f99d-5db0-4867-bcb7-c2488a2598d7',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 3: SHIPPING ADDRESS',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-a57a3299-8f3a-4e2f-a3e9-2990ea7c62cf',
        },
      },
      {
        type: 'ShippingForm',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ShippingForm-99e07484-b51e-4805-9f1c-3116f6fa78df',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 4: BIILLING ADDRESS',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-3c077bcb-8658-419c-b4d5-7f594edb90c3',
        },
      },
      {
        type: 'BillingForm',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'BillingForm-0a507ee8-a7c1-47e6-83a7-fd423ea47717',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 5: SHIPPING METHODS',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-1dcd1c2c-9a1f-4fde-8704-8648c8d5ede4',
        },
      },
      {
        type: 'ShippingMethodSelect',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ShippingMethodSelect-d9ed17ba-eb91-4e29-a44e-62913aa6f454',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 6: PAYMENT METHODS',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-f09533f8-e7e0-4220-a6f9-909dd1b2f50f',
        },
      },
      {
        type: 'Payment',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'Payment-54d3978b-fb03-4e95-ae8e-c883c4896954',
        },
      },
      {
        type: 'ConfirmPurchase',
        props: {
          nextPagePath: {
            label: '',
            value: '',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ConfirmPurchase-73b8b314-34e7-4f01-be85-c2aa546254fb',
        },
      },
    ],
    'Columns-878910bf-dce4-4e1e-9abb-0749dc68164a:column-1': [
      {
        type: 'Accordion',
        props: {
          backgroundColor: '#e4e4e4',
          accordionOpenText: 'Accordion Open Text',
          accordionCloseText: 'Accordion Close Text',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-1',
              left: 'max-sm:pl-0',
            },
          },
          showTotal: true,
          id: 'Accordion-5c39cd9c-4a26-4335-ac5a-cf4c4c622d73',
        },
      },
    ],
    'Accordion-5c39cd9c-4a26-4335-ac5a-cf4c4c622d73:Accordion': [
      {
        type: 'Cart',
        props: {
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'Cart-3b868911-8333-4ec6-95d9-f4dd64de9b3b',
        },
      },
      {
        type: 'OrderDetails',
        props: {
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'OrderDetails-17c06bb4-5e1c-4e1d-a485-c1abfeefff70',
        },
      },
      {
        type: 'PromoCode',
        props: {
          placeholder: 'Promo Code (Case Sensitive)',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'PromoCode-c28a2d26-6f23-4bc5-bac2-070e0b98b5db',
        },
      },
      {
        type: 'CustomerReviews',
        props: {
          placeHolder: 'Customer Reviews',
          reviews: [
            {
              id: '2dfea495-88cd-41d2-b269-d1ef8349e5ce',
              name: 'Aiden H',
              review:
                "I love the Thor's Hammer Celtic Knot Viking Ring! It beautifully combines Viking lore with elegance. The craftsmanship is top-notch, making it lightweight yet durable for everyday wear. Highly recommend for its superb quality and style.",
            },
            {
              id: '5571885a-f19e-40d2-bd4a-37203c6d3cf4',
              name: 'John T',
              review:
                'The Vintage Celtic Cross Viking necklace is a fantastic purchase! The pendant is solid with intricate detailing, while the chain complements it perfectly. It’s a standout accessory that’s both stylish and sturdy.',
            },
            {
              id: '3aea72de-cc11-4582-b1c7-700fa025d9e0',
              name: 'Kevin L',
              review:
                'I really like the Cross Skull Bead Bracelet! It’s weighty and the skull details add a cool, rugged look. It’s comfortable for all-day wear and perfect for enhancing a rugged style.',
            },
          ],
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'CustomerReviews-0660cd33-1b5e-40db-95bf-08024a1ac29a',
        },
      },
      {
        type: 'CustomerGuarantees',
        props: {
          placeHolder: 'Guarantees',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          guarantees: [
            {
              id: 'd4a3bf19-6959-431c-91a6-df2eda37e1ce',
              img_src: 'calendar',
              name: 'Items in your cart are in high demand. No Worries, we reserved your order.',
              description:
                "We ship out quality products, it's just one of the reasons we have so many happy repeat customers!",
            },
            {
              id: 'b9207112-5400-4a55-b93a-d80e72723a75',
              img_src: 'delivery',
              name: "If you're not satisfied with your product(s), we'll make it right. That's our promise!",
              description:
                "If you're not satisfied with your product(s), we'll make it right. That's our promise!",
            },
          ],
          id: 'CustomerGuarantees-ba2ec638-a384-4d4c-9d3a-c525c22539c6',
        },
      },
      {
        type: 'VipComponent',
        props: {
          title: 'VIP Club',
          description:
            'By taking advantage of this offer, you will be signed up for our exclusive VIP Club program.  ',
          vipClubBenefitTitle:
            'The VIP Club is an exclusive program that provides you with',
          vipClubBenefits:
            'Access to VIP pricing on all website products.,\n    Access to hundreds of trending products through the VIP Portal.,\n    Lowest price guarantee on all VIP Portal orders.,\n    Priority 24/7 customer support.',
          content:
            '<p><span style="color: rgb(0, 0, 0);">You will be billed 29 dollars 99 cents monthly till cancellation. If you do not want to continue the VIP Club after the introductory period or if you wish to cancel the VIP Club at any time, you may do so by filling out the&nbsp;</span><a href="https://offer.enhanced-essence.com/pages/membership-cancellation" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 57, 201);">Cancel Membership</a><span style="color: rgb(0, 0, 0);">&nbsp;form on our Website, by emailing us or filling out the form on our website now at least 3 days before the next billing period with your full name and the email and physical address associated with your use of the Service.</span></p>',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-4',
              left: 'pl-4',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          font: [
            {
              size: 'text-2xl',
              weight: 'font-normal',
            },
          ],
          alignItems: 'items-center',
          id: 'VipComponent-bbd44a03-0f2b-44fa-8147-c540a3e97fc0',
        },
      },
    ],
  },
};

export const SimpleLayout = {
  content: [
    {
      type: 'Container',
      props: {
        position: '',
        flexDirection: 'row',
        backgroundColor: 'none',
        border: false,
        borderRadius: 'rounded-none',
        borderWidth: 'border-0',
        borderColor: '#000000',
        margin: {
          desktop: {
            top: 'mt-8',
            bottom: 'mb-0',
            right: 'mr-36',
            left: 'ml-36',
          },
          tablet: {
            top: 'max-md:mt-6',
            bottom: 'max-md:mb-0',
            right: 'max-md:mr-12',
            left: 'max-md:ml-12',
          },
          mobile: {
            top: 'max-sm:mt-4',
            bottom: 'max-sm:mb-0',
            right: 'max-sm:mr-4',
            left: 'max-sm:ml-4',
          },
        },
        padding: {
          desktop: {
            top: 'pt-0',
            bottom: 'pb-0',
            right: 'pr-0',
            left: 'pl-0',
          },
          tablet: {
            top: 'max-md:pt-1',
            bottom: 'max-md:pb-1',
            right: 'max-md:pr-1',
            left: 'max-md:pl-1',
          },
          mobile: {
            top: 'max-sm:pt-1',
            bottom: 'max-sm:pb-1',
            right: 'max-sm:pr-1',
            left: 'max-sm:pl-1',
          },
        },
        gap: 'gap-2',
        id: 'Container-0e98afb7-cef8-4194-a5b8-531ebc02a1bc',
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'sans-serif',
      themeColor: {
        r: 79,
        g: 70,
        b: 229,
      },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'Columns-01547c8e-4222-4889-a9cc-9c063c7f796a:column-0': [
      {
        type: 'Header',
        props: {
          backgroundImageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'background',
            BlobURL: 'https://picsum.photos/id/0/5000/3333',
          },
          imageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'logo',
            BlobURL: 'https://picsum.photos/id/10/2500/1667',
          },
          headerText: 'My Store',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          image: {
            imageType: 'inline',
            backgroundPosition: 'bg-center',
            backgroundRepeat: 'bg-no-repeat',
            backgroundSize: 'bg-cover',
            borderRadius: 'rounded-md',
            border: 'border-none',
            maxWidth: 'w-auto',
            height: 'h-[100px]',
          },
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          color: {
            backgroundColor: 'bg-transparent',
            hoverColor: 'hover:text-white',
            textColor: 'text-white',
          },
          id: 'Header-4fc2bd4b-541a-4510-86c2-c9dcab0c3c21',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 1: INTERESTED IN ANYTHING ELSE?',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-8',
              bottom: 'max-md:mb-4',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-8',
              bottom: 'max-sm:mb-4',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-dd508552-1803-4dc3-ae38-6f133cb60620',
        },
      },
      {
        type: 'UpSell',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'UpSell-0c037ce6-83e4-45da-a51d-1561402ca68e',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 2: CONTACT INFORMATION',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-8',
              bottom: 'max-md:mb-4',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-8',
              bottom: 'max-sm:mb-4',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-f0dd4034-8098-4962-afd3-27e7ea975d40',
        },
      },
      {
        type: 'ContactInfo',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'ContactInfo-ad242a52-06bc-4ae2-90fc-10324fe1fee1',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 3: SHIPPING ADDRESS',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-8',
              bottom: 'max-md:mb-4',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-8',
              bottom: 'max-sm:mb-4',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-f64ff2f3-19af-4a21-9d54-57c7ecc5cc8c',
        },
      },
      {
        type: 'ShippingForm',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'ShippingForm-862265bc-43d7-4b1b-86b0-59c4ab31262e',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 4: SHIPPING METHODS',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-8',
              bottom: 'max-md:mb-4',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-8',
              bottom: 'max-sm:mb-4',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-68ad7045-0bfe-4ec8-b230-73e7d09cf95b',
        },
      },
      {
        type: 'ShippingMethodSelect',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ShippingMethodSelect-8bbb09f4-89a4-4f3f-9c87-3d5076eaf685',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 5: BILLING ADDRESS',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-8',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-8',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-e8b2b443-1d4f-4a06-8bef-b571c7a7ede5',
        },
      },
      {
        type: 'BillingForm',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'BillingForm-0639087b-b702-46e4-9468-850b79755623',
        },
      },
      {
        type: 'TextField',
        props: {
          header: 'STEP 6: PAYMENT',
          margin: {
            desktop: {
              top: 'mt-8',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-8',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-8',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          font: {
            size: 'text-xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-3d04d442-87a2-4070-92eb-f1e593bac6aa',
        },
      },
      {
        type: 'Payment',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Payment-be7f9603-20fe-4fd3-9096-2330371e1a05',
        },
      },
      {
        type: 'ConfirmPurchase',
        props: {
          nextPagePath: {
            label: '/test',
            value:
              '/page-builder//test?tab=1&editMode=false&pageID=401b8920-151d-412c-833e-ababcf982de0&storeID=f6720d76-202f-4616-a877-eb9560f2fddc&store=sandbox-2024&cart=Z2NwLWFzaWEtc291dGhlYXN0MTowMUoxVk5TM0tBUDNYWTZTSDNGOVM4NEEwRA&template=checkout',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ConfirmPurchase-9dd7e197-8d0d-4b4f-b611-20b0a856b21a',
        },
      },
    ],
    'Accordion-e03113ad-daf1-47fe-80bd-e44875a1cc12:Accordion': [
      {
        type: 'Cart',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Cart-ff11101d-fb8c-4be8-88ad-11197139704c',
        },
      },
      {
        type: 'PromoCode',
        props: {
          placeholder: 'Promo Code (Case Sensitive)',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'PromoCode-be38be91-caea-4c70-8baf-734be7ad4d2f',
        },
      },
      {
        type: 'OrderDetails',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'OrderDetails-a3786525-3cb2-47b4-b86a-92c11418eeb5',
        },
      },
      {
        type: 'CustomerGuarantees',
        props: {
          placeHolder: 'Guarantees',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          guarantees: [
            {
              id: '8628f167-4a55-4421-9c0d-4ba83641598c',
              img_src: 'calendar',
              name: 'Items in your cart are in high demand. No Worries, we reserved your order.',
              description:
                "We ship out quality products, it's just one of the reasons we have so many happy repeat customers!",
            },
          ],
          id: 'CustomerGuarantees-39a6b9b8-8cd1-4495-a236-93957b4ce3de',
        },
      },
      {
        type: 'CustomerReviews',
        props: {
          placeHolder: 'Customer Reviews',
          reviews: [
            {
              id: 'eada1f21-94e1-4343-bffd-174b023fad28',
              name: 'Aiden H',
              review:
                "I love the Thor's Hammer Celtic Knot Viking Ring! It beautifully combines Viking lore with elegance. The craftsmanship is top-notch, making it lightweight yet durable for everyday wear. Highly recommend for its superb quality and style.",
            },
          ],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'CustomerReviews-fc5edc01-43a8-45c3-afd0-f500bf8874ec',
        },
      },
    ],
    'Columns-01547c8e-4222-4889-a9cc-9c063c7f796a:column-1': [
      {
        type: 'Accordion',
        props: {
          backgroundColor: '#f8f8f9',
          accordionOpenText: 'Accordion Open Text',
          accordionCloseText: 'Accordion Close Text',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-8',
              left: 'pl-8',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-8',
              left: 'max-md:pl-8',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-4',
              left: 'max-sm:pl-4',
            },
          },
          showTotal: true,
          id: 'Accordion-e03113ad-daf1-47fe-80bd-e44875a1cc12',
        },
      },
    ],
    'Container-0e98afb7-cef8-4194-a5b8-531ebc02a1bc:Container': [
      {
        type: 'Columns',
        props: {
          gap: 'gap-4',
          distribution: 'auto',
          columns: [{}, {}],
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          direction: 'column',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Columns-01547c8e-4222-4889-a9cc-9c063c7f796a',
        },
      },
    ],
  },
};
