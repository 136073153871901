import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../../page-builder/puck/reusable-props/margin';
import { PostUpSellTimer } from './PostUpSellTimer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux-setup/store';
import {
  PaddingProps,
  paddingFields,
} from '../../../../page-builder/puck/reusable-props/padding';
import {
  JustifyContentField,
  JustifyContentProps,
} from '../../../../page-builder/puck/reusable-props/justify-content';
import {
  fontField,
  FontProps,
} from '../../../../page-builder/puck/reusable-props/font';

export type TimerProps = {
  Description: string;
} & MarginProps &
  PaddingProps &
  JustifyContentProps &
  FontProps;

export const Timer: ComponentConfig<TimerProps> = {
  fields: {
    Description: { type: 'text', label: 'Description' },
    ...marginFields,
    ...paddingFields,
    ...JustifyContentField,
    ...fontField,
  },
  defaultProps: {
    margin: {
      desktop: {
        top: 'mt-0',
        bottom: 'mb-0',
        right: 'mr-0',
        left: 'ml-0',
      },
      tablet: {
        top: 'max-md:mt-0',
        bottom: 'max-md:mb-0',
        right: 'max-md:mr-0',
        left: 'max-md:ml-0',
      },
      mobile: {
        top: 'max-sm:mt-0',
        bottom: 'max-sm:mb-0',
        right: 'max-sm:mr-0',
        left: 'max-sm:ml-0',
      },
    },
    padding: {
      desktop: {
        top: 'pt-0',
        bottom: 'pb-0',
        right: 'pr-0',
        left: 'pl-0',
      },
      tablet: {
        top: 'max-md:pt-0',
        bottom: 'max-md:pb-0',
        right: 'max-md:pr-0',
        left: 'max-md:pl-0',
      },
      mobile: {
        top: 'max-sm:pt-0',
        bottom: 'max-sm:pb-0',
        right: 'max-sm:pr-0',
        left: 'max-sm:pl-0',
      },
    },
    justifyContent: 'justify-center',
    Description: 'RESERVED FOR THE NEXT:',
    font: {
      size: 'text-2xl',
      weight: 'font-bold',
    },
  },
  render: ({ margin, padding, justifyContent, Description, font }) => {
    const postUpsell = useSelector((state: RootState) => state.postUpSell);
    const postUpsellState = useSelector(
      (state: RootState) => state.postUpSellState,
    );
    const postUpSellRecommendation = postUpsell.postUpsellData;

    const currentRecommendation =
      postUpSellRecommendation[postUpsellState.currentIndex];
    return (
      <div
        className={`flex flex-col ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`}>
        <div className={` ${font?.size} ${font?.weight}`}>
          <h1>{currentRecommendation.Title}</h1>
          <h2>{Description}</h2>
        </div>
        <div className={`flex  ${justifyContent} pt-3`}>
          <PostUpSellTimer />
        </div>
      </div>
    );
  },
};
