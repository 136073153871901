import React, { ChangeEvent, useCallback, useState } from 'react';
import { Select } from '../../../page-builder/components/form-fields/Select';
import { formatPrice } from '../../../page-builder/utils/formatPrice';
import { ShippingMethod } from '../../../page-builder/interface/checkoutInterface';
import {
  Item,
  Variant,
  LineItem,
} from '../../../page-builder/interface/checkoutInterface';
import checkoutService from '../../../page-builder/api/checkoutService';
import {
  AddItemPayload,
  CheckoutUpdateItemsPayload,
} from '../../../page-builder/interface/apiServiceInterface';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setAvailableShippingMethods } from '../../../page-builder/redux/slices/checkoutSlice';
import {
  setIsCartLoading,
  setCartData,
} from '../../../page-builder/redux/slices/cartSlice';
import { setCheckoutErrorMessage } from '../../../page-builder/redux/slices/commonSlice';

interface VariantsProps {
  variant: Item;
}

export const UpSellVariant: React.FC<VariantsProps> = ({ variant }) => {
  const dispatch = useAppDispatch();
  const cartData = useAppSelector(state => state.cart?.cartData);
  const isCartLoading = useAppSelector(state => state.checkout.isApiLoading);
  const storeData = useAppSelector(state => state.store.storeData);

  const [isAddedToCart, setIsAddedToCart] = useState<boolean>(false);
  const [selectedVariant, setSelectedVariant] = useState<Variant>(
    variant.Variants[0],
  );

  React.useEffect(() => {
    if (!cartData || !selectedVariant) {
      setIsAddedToCart(false);
      return;
    }

    const lineItems = cartData.LineItems;
    const isVariantInCart = lineItems?.some(
      (item: LineItem) => item.VariantID === selectedVariant.ID,
    );
    setIsAddedToCart(isVariantInCart);
  }, [cartData, selectedVariant]);

  const handleVariantChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newVariant = variant?.Variants.find(v => v.ID === event.target.value);
    if (newVariant) {
      setSelectedVariant(newVariant);
    }
  };

  const titleShouldCenter = variant?.Variants?.length <= 1;

  const handleAdd = useCallback(async () => {
    dispatch(setIsCartLoading(true));
    if (!cartData?.ID || !storeData?.ShopID || !storeData?.StoreID) return;
    const lineItems = [
      {
        Quantity: 1,
        VariantID: selectedVariant.ID,
      },
    ];

    const payload: AddItemPayload = {
      ID: cartData?.ID,
      LineItems: lineItems,
      ShopID: storeData?.ShopID,
      StoreID: storeData?.StoreID,
    };

    try {
      const res = await checkoutService.checkoutAddItem(payload);
      dispatch(setCartData(res.data.Cart));
      dispatch(
        setAvailableShippingMethods(
          res.data.Cart.AvailableShippingMethods as ShippingMethod[],
        ),
      );
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setCheckoutErrorMessage(error.message)); // TODO: error message to sentry
      }
    } finally {
      dispatch(setIsCartLoading(false));
    }
  }, [
    dispatch,
    cartData?.ID,
    storeData?.ShopID,
    storeData?.StoreID,
    selectedVariant,
  ]);

  const handleRemove = useCallback(async () => {
    dispatch(setIsCartLoading(true));
    const lineItem = cartData?.LineItems.find(
      (item: LineItem) => item.VariantID === selectedVariant.ID,
    );

    const ID = lineItem?.ID;
    const lineItems = [
      {
        ID: ID,
        Quantity: 0,
        VariantID: selectedVariant.ID,
      },
    ];

    const payload = {
      ID: cartData?.ID,
      LineItems: lineItems,
      ShopID: storeData?.ShopID,
      StoreID: storeData?.StoreID,
    };

    try {
      const res = await checkoutService.checkoutUpdateItems(
        payload as CheckoutUpdateItemsPayload,
      );
      dispatch(setCartData(res.data.Cart));
      dispatch(
        setAvailableShippingMethods(
          res.data.Cart.AvailableShippingMethods as ShippingMethod[],
        ),
      );
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setCheckoutErrorMessage(error?.message)); // TODO: error message to sentry
      }
    } finally {
      dispatch(setIsCartLoading(false));
    }
  }, [
    dispatch,
    cartData,
    storeData?.ShopID,
    storeData?.StoreID,
    selectedVariant,
  ]);

  return (
    <li
      className={`items-center px-3 py-2 sm:px-4 sm:py-3 md:px-5 md:py-4 rounded-lg  ${isAddedToCart ? 'bg-gray-100' : 'bg-white'}`}>
      <div className="flex items-center">
        <div className="w-3/12 md:w-2/12">
          <img
            width={100}
            height={100}
            src={selectedVariant?.ImageURL || ''}
            alt={selectedVariant?.Title || 'Product Image'}
            className="h-20 w-20 flex-none rounded-md object-cover object-center"
          />
        </div>
        <div className="w-9/12 md:w-10/12 flex flex-col justify-center">
          <div className={`flex ${titleShouldCenter ? 'items-center' : ''}`}>
            <div
              className={`${titleShouldCenter ? 'w-full' : 'w-8/12 md:w-10/12'} ml-3`}>
              <div className="text-xs bg-red-700 text-white inline-block py-1 my-2 px-2 rounded-md">
                OFFER ENDS TODAY
              </div>
              <div className="text-md text-gray-950 font-bold">
                {variant?.Title}
              </div>
              {variant?.Variants?.length > 1 && (
                <Select
                  className="w-full sm:w-3/4 md:w-6/12 lg:w-8/12 py-2"
                  additionalStyles="py-1"
                  options={variant.Variants}
                  onChange={handleVariantChange}
                  value={selectedVariant?.ID}
                  valueKey="ID"
                  labelKey="Title"
                />
              )}
            </div>
            {selectedVariant?.Price && (
              <div className="w-4/12 md:w-3/12 text-right ml-2 flex flex-col justify-center items-end">
                {selectedVariant?.OriginalPrice &&
                  Number(selectedVariant?.OriginalPrice) >
                    Number(selectedVariant?.Price) && (
                    <div className="text-sm font-light line-through">
                      ${formatPrice(selectedVariant?.OriginalPrice)}
                    </div>
                  )}
                <div className="text-lg text-gray-950 font-medium text-right">
                  ${formatPrice(selectedVariant?.Price)}
                </div>
                {isAddedToCart ? (
                  <button
                    //TODO: Add styles
                    // id={styles['continue-button']}
                    onClick={() => {
                      handleRemove();
                      setIsAddedToCart(false);
                    }}
                    disabled={isCartLoading}
                    type="button"
                    className="py-1.5 mt-1 px-3 text-white bg-page_builder_theme text-sm font-semibold rounded-md ">
                    Remove
                  </button>
                ) : (
                  <button
                    // TODO: Add styles
                    // id={styles['continue-button']}
                    onClick={() => {
                      handleAdd();
                      setIsAddedToCart(true);
                    }}
                    disabled={isCartLoading}
                    type="button"
                    className="w-auto py-1.5 mt-1 px-3 text-white bg-page_builder_theme text-sm font-semibold rounded-md focus:outline-none">
                    Add
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
