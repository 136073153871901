import { useLocation, useSearchParams } from 'react-router-dom';
import HomePage from '../../page-builder/pages/page';
import { useEffect, useState } from 'react';
import { Config, Data } from '@measured/puck';
import PostUpsellConfig from '../../page-builder/puck/post-upsell-puck-config';
import config from '../../page-builder/puck/puck.config';
import FreeTemplateconfig from '../../page-builder/puck/free-template-puck-config';
import { ConvertBase64ToJson } from '../../page-builder/puck/reusable-props/convertJson';
import globalPageService from '../services/pageService';
import _ from 'lodash';
import { ICheckoutPage } from '../interface/pageInterface';

const Home = () => {
  const { pathname } = useLocation();
  const query = new URLSearchParams(location.search).get('template') || '';
  const [searchParams] = useSearchParams();
  const storeID = searchParams.get('storeID') || '';
  const pageID = searchParams.get('pageID') || '';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkoutPage, setCheckoutPage] = useState<ICheckoutPage | null>(null);
  const [puckConfig, setPuckConfig] = useState<Config | null>();
  const [puckData, setPuckData] = useState<Data>();

  const getPageDeatils = async () => {
    setIsLoading(true);
    const res = await globalPageService.getPage(storeID, pageID);
    if (res?.status === 200 && res?.data) {
      setCheckoutPage(res?.data);
      const configData = ConvertBase64ToJson(res?.data?.PageData);
      if (!_.isEmpty(configData)) {
        setPuckData(configData);
        setPuckConfig(
          query === 'upsell'
            ? (PostUpsellConfig as Config)
            : query === 'checkout'
              ? (config as Config)
              : query === 'confirmation'
                ? (config as Config)
                : (FreeTemplateconfig as Config),
        );
      }
    }
    setIsLoading(false);
  };

  const onSuccesCallBack = () => {
    getPageDeatils();
  };

  useEffect(() => {
    getPageDeatils();
  }, [pathname, searchParams]);

  return (
    <>
      {!isLoading && puckConfig && puckData && (
        <HomePage
          config={puckConfig}
          data={puckData}
          checkoutPage={checkoutPage}
          onSuccesCallBack={onSuccesCallBack}
        />
      )}
    </>
  );
};

export default Home;
