import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShippingMethod } from '../../../page-builder/interface/checkoutInterface';
import { CheckoutDataPayload } from '../../../page-builder/interface/apiServiceInterface';

export interface CheckoutState {
  isApiLoading: boolean;
  availableShippingMethods: ShippingMethod[];
  selectedShippingMethod: ShippingMethod | null;
  isDeclinePopup: boolean;
  declineReasonCode: string;
  checkoutOrderID: string;
  purchaseToken: string;
  hasSubscription: boolean;
}

const initialState: CheckoutState = {
  isApiLoading: false,
  availableShippingMethods: [],
  selectedShippingMethod: null,
  isDeclinePopup: false,
  declineReasonCode: '',
  checkoutOrderID: '',
  purchaseToken: '',
  hasSubscription: false,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setAvailableShippingMethods(
      state,
      action: PayloadAction<ShippingMethod[]>,
    ) {
      state.availableShippingMethods = action.payload;
    },
    setApiLoading(state, action: PayloadAction<boolean>) {
      state.isApiLoading = action.payload;
    },
    setSelectedShippingMethod(state, action: PayloadAction<ShippingMethod>) {
      state.selectedShippingMethod = action.payload;
    },
    setIsDeclinePopup(state, action: PayloadAction<boolean>) {
      state.isDeclinePopup = action.payload;
    },
    setDeclineReasonCode(state, action: PayloadAction<string>) {
      state.declineReasonCode = action.payload;
    },
    setCheckoutOrderID(state, action: PayloadAction<string>) {
      state.checkoutOrderID = action.payload;
    },
    setPurchaseToken(state, action: PayloadAction<string>) {
      state.purchaseToken = action.payload;
    },
    setHasSubscription(state, action: PayloadAction<CheckoutDataPayload>) {
      state.hasSubscription = action.payload.Cart.HasSubscription;
    },
  },
});

export const {
  setApiLoading,
  setSelectedShippingMethod,
  setIsDeclinePopup,
  setDeclineReasonCode,
  setCheckoutOrderID,
  setPurchaseToken,
  setHasSubscription,
  setAvailableShippingMethods,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
