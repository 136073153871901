import { FieldLabel, Fields } from '@measured/puck';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export type QuillTextEditorProps = {
  content: string;
};

const modules = {
  toolbar: [
    [{ header: [] }, 'bold', 'italic', 'underline', 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }, 'clean'],
    [{ color: [] }, { background: [] }],
  ],
};

const formats = [
  'header',
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'color',
  'background',
  'link',
];

export const QuillTextEditor = (type: string): Fields<QuillTextEditorProps> => {
  return {
    content: {
      type: 'custom',
      render: ({ value, onChange }) => {
        return (
          <FieldLabel label={`${type}`}>
            <ReactQuill
              className="pagebuilder-theme"
              theme={'snow'}
              onChange={onChange}
              value={value}
              modules={modules}
              formats={formats}
              placeholder={'create content here...'}
            />
          </FieldLabel>
        );
      },
    },
  };
};
