import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import { thunk } from 'redux-thunk';

import ColoumnFilterSlice from '../redux-setup/slices/ColoumnFilterSlice';
import userReducer from '../redux-setup/slices/userSlice';
import pathReducer from '../redux-setup/slices/pageConfigSlice';
import orderReducer from '../redux-setup/slices/OrderSlice';
import permissionReducer from '../redux-setup/slices/permissionSlice';
import invitationSlice from '../redux-setup/slices/InvitationSlice';
import shopifySlice from '../redux-setup/slices/shopifySlice';
import StoreIdSlice from './slices/StoreIdSlice';
import inviteUserSlice from './slices/InviteUser';
import themeSlice from './slices/themeSlice';

// puck slices
import cartSlice from '../page-builder/redux/slices/cartSlice';
import storeSlice from '../page-builder/redux/slices/storeSlice';
import commonSlice from '../page-builder/redux/slices/commonSlice';
import checkoutSlice from '../page-builder/redux/slices/checkoutSlice';
import preUpsellSlice from '../page-builder/redux/slices/preUpsellSlice';
import termsAndSubSlice from '../page-builder/redux/slices/termsAndSubSlice';
import postUpsellSlice from '../page-builder/redux/slices/postUpsellSlice';
import postUpsellStateSlice from '../page-builder/redux/slices/postUpsellStateSlice';

const rootReducer = combineReducers({
  user: userReducer,
  pathConfig: pathReducer,
  order: orderReducer,
  permission: permissionReducer,
  invitation: invitationSlice,
  coloumnFilter: ColoumnFilterSlice,
  storeIds: StoreIdSlice,
  inviteUser: inviteUserSlice,
  theme: themeSlice,
  // puck slices
  checkout: checkoutSlice,
  cart: cartSlice,
  store: storeSlice,
  common: commonSlice,
  preUpSell: preUpsellSlice,
  postUpSell: postUpsellSlice,
  postUpSellState: postUpsellStateSlice,
  termsAndSub: termsAndSubSlice,

  shopify: shopifySlice,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['shopify'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
